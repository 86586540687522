import { createRouter,createWebHashHistory} from 'vue-router'
import Home from '../views/wanhuaguPcView/homePage/homePage.vue'
import login from '../components/login/index'
import routers from './wanhuaguPCViews/index.js'
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  ...routers
]

const router = createRouter({
    //createWebHashHistory
    // createWebHistory
  history: createWebHashHistory('/'),
  routes
})
// router.beforeEach((to, from, next) => {
// 	if (!localStorage.getItem('sessionID')) {
// 		if (to.name == "home"||to.name == "articleDetails") {
// 			next();
// 		} else {
//       login(to.fullPath)
// 		}
// 	} else {
// 		next();
// 	}
// });
export default router
