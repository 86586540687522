<template>
  <div class="breadcrumbs">
    <router-link v-if="store.state.language" :to="{ path: '/' }">首页</router-link>
    <router-link v-if="!store.state.language" :to="{ path: '/' }">Home</router-link>
    <span v-for="(breadcrumb, index) in breadcrumbs" :key="index">
      &gt;
      <router-link class="bre" v-if="store.state.language" :to="breadcrumb.path">{{ breadcrumb.title_CN }}</router-link>
      <router-link class="bre" v-if="!store.state.language" :to="breadcrumb.path">{{ breadcrumb.title_EN }}</router-link>
    </span>
  </div>
</template>
<script>
import store from "@/store";

export default {
  name: 'Breadcrumb',
  computed: {
    store() {
      return store
    }
  },
  data() {
    return {
      breadcrumbs: [],
    };
  },
  created() {
    this.setBreadcrumbs();
  },
  methods: {
    setBreadcrumbs() {
      const matchedRoutes = this.$route.matched.slice().reverse();
      this.breadcrumbs = matchedRoutes.map(route => {
        return {
          path: route.path,
          title_CN: route.meta.title_CN,
          title_EN: route.meta.title_EN,
        };
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.breadcrumbs {
  margin-bottom: 10px;
  span{
    margin: 0 5px;
  }
}
.breadcrumbs span:not(:last-child)::after {
  content: ">";
  margin: 0 5px;
}
.bre{
  color: #214A9A;
  font-weight: 500;
}
</style>