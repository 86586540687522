import { getOneMemberDetail } from "../../common/api"
export default {
    namespaced: true,
    state() {
        return {
            userinfo: {},
        }
    },
    mutations: {
        userinfo(state, data) {
            state.userinfo = { ...data }
        }
    },
    actions: {
        //获取用户信息
        // getUserInfo(context) {
        //     getOneMemberDetail({}, res => {
        //         if (res.data.header.code != 0) {
        //             window.localStorage.clear()
        //         } else {
        //             context.commit('userinfo', res.data.body)
        //         }
        //     })
        // }
    }
}