<template>
  <div class="about">
    <!-- 地图 -->
    <div id="samiraMap"></div>
  </div>
</template>

<script setup>
import {defineExpose, nextTick, reactive, inject, ref, onMounted, getCurrentInstance} from "vue";

const {proxy, ctx} = getCurrentInstance()
//腾讯地图的key  在public的html导入script   .getSuggestions也有key引入
const TMap = window.TMap;
const emit = defineEmits(['handleAdd'])


const data = reactive({
  input: "",
  zoom: 16,
  map: null
})

var map;
var marker;
//地图显示
const reserMap = (lat, lng, zoom) => {
  console.log(lat,lng)
  const center = new TMap.LatLng(lat, lng);
  const mapContainer = document.getElementById("samiraMap");
  // console.log(mapContainer,'mapContainer')
  // return
  try {
    nextTick(() => {
      map = new TMap.Map(mapContainer, {
        center: center,
        draggable: true,
        zoom: zoom,
      });

      marker = new TMap.MultiMarker({
        // position: new TMap.LatLng(lat, lng),
        map: map,
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          "myStyle": new TMap.MarkerStyle({
            "width": 25,  // 点标记样式宽度（像素）
            "height": 35, // 点标记样式高度（像素）
            // "src": '../assets/img/address.png',  //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            "anchor": {x: 16, y: 32}
          })
        },
        //点标记数据数组
        geometries: [{
          "id": "1",   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
          "styleId": 'myStyle',  //指定样式id
          "position": new TMap.LatLng(lat, lng),  //点标记坐标位置
          "properties": {//自定义属性
            "title": "marker1"
          }
        }
        ]
      });
    })
  } catch (e) {
    console.log(e, 'catch')
  }
}

//当我点击哪一个的时候改变背景色
// const chooseAdress = (data, index) => {
//   let lat = data.location.lat;
//   let lng = data.location.lng;
//   //修改地图中心点
//   // console.log(marker,'chhosse')
//   // marker.updateGeometries([
//   //   {
//   //     "styleId": "myStyle",
//   //     "id": "1",
//   //     "position": new TMap.LatLng(lat, lng),
//   //   }
//   // ])
//   reserMap(data.location.lat, data.location.lng, data.zoom);
//   ctx.$emit('qqGetLocation', lat, lng)
// }
// 修改地图位置
const setInitChange = (lat,lng) => {
  map.setCenter(new TMap.LatLng(lat, lng));
  marker.updateGeometries([
    {
      styleId: "myStyle",
      id: "1",
      position: new TMap.LatLng(lat, lng),
    },
  ]);
  emit('qqGetLocation', lat, lng)
}


// 搜索
const search = () => {
  const suggest = new TMap.service.Suggestion({
    pageSize: 10, // 返回结果每页条目数
    regionFix: false, // 搜索无结果时是否固定在当前城市
    servicesk: "7TVBZ-DGTWF-O6FJ6-NKQNG-SHXKF-2OFMQ", // key
  });
  suggest.getSuggestions({
    keyword: data.input,
    servicesk: "7TVBZ-DGTWF-O6FJ6-NKQNG-SHXKF-2OFMQ", // 签名（可在腾讯地图开放平台中拿）
  }).then((result) => {
    if (result.data.length === 0) return proxy.$message.error('搜索不到该地址')
    // chooseAdress(result.data[0])
    let data = result.data[0]
    setInitChange(data.location.lat,data.location.lng)
  });
}

const clickIpt = (input) => {
  data.input = input
  search();
}


// onMounted(() => {
//   reserMap(39.908823, 116.39747, data.zoom);
// })

defineExpose({
  reserMap,
  clickIpt,
  setInitChange
});
</script>

<style scoped>
.about {
  width: 100%;
  height: 100%;
}

#samiraMap {
  width: 100%;
  height: 100%;
}
</style>