<!-- 
  组件来自页面区块《公司价值》改造而成/外观为四个方块/方块中显示4:3大图和文章标题简介
组件需要传入数据。直接传文章数组/传导航id然后让组件查。二选一。都传的会用直接传的数据
使用示例：
<blockA :values="data.CompanyValue" :navigatorID="'2f997fc0c5184d50954a9396638abc7f'"/> 
-->

<template>
  <div class="CompanyValue">
    <div class="title_ZH" v-if="store.state.language">{{ naviName }}</div>
    <div class="title_EN" v-if="!store.state.language">{{ naviNameEN }}</div>
  </div>
  <div class="companyValue">
    <div class="CivilAviationOperations" v-for="(item, index) in data" :key="index" @click="toArticle(item, index)">
      <div class="listImage">
        <img :src="item.listImage" alt="">
      </div>
      <div class="description_ZH" v-if="store.state.language">{{ item.name }}</div>
      <div class="description_EN" v-if="!store.state.language">{{ item.subTitle }}</div>
      <div class="content_ZH" v-if="store.state.language" v-html="item.description"></div>
      <div class="content_EN" v-if="!store.state.language" v-html="item.descriptionEnglish"></div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import { getNavigatorList, getNavigatorSimpleArticleList } from "@/common/api";
import router from "@/router";
import Common from "@/common/common";


export default {
  components: { Common },
  name: 'blockA',
  computed: {
    store() {
      return store
    }
  },
  data() {
    return {
      data: [],
      naviName: "",
      naviNameEN: "",
    };
  },
  props: {
    values: [],
    navigatorID: String,
  },
  created() {
    //while we do not know which navi(header or main) need detail,we get both,the wrong one will not apply beacuse nocan find data
    //this.getNaviDetail(Common.navIdList.RecommendedNavigationId)
    //this.getNaviDetail(Common.navIdList.headerId)

    //从store缓存中查询 naviID和传入的相同的项，找到name和shortname（上面的方法是从接口查，没这个必要
    //因为有首页navi和主页navi两种，都跑一遍，只有其中一个会找到值
    let headerlist = Object.values(store?.state?.nav?.headerList);
    headerlist.forEach(li => {
      if (li.navigatorID === this.navigatorID) { this.naviName = li.name; this.naviNameEN = li.shortName }
    });
    let navlist = Object.values(store?.state?.nav?.navList);
    navlist.forEach(li => {
      if (li.navigatorID === this.navigatorID) { this.naviName = li.name; this.naviNameEN = li.shortName }
    });

    //get article list 
    if (this.values !== undefined && this.values.length !== 0) {
      this.data = this.values;
    }
    //if nodata we get 
    else {
      console.log(this.navigatorID);
      getNavigatorSimpleArticleList({
        navigatorID: this.navigatorID,
        sortTypeOrder: 1
      }, res => {
        this.data = res.data.body.data.rows;
      });
    }
  },
  methods: {
    //文章跳转方法
    toArticle(item, index) {
      console.log(item.name)
      this.$router.push(`/mainArticleDetails?articleID=${item.articleID}`);
    },
    //根据navigatorID获取区域name
    getNaviDetail(id) {
      //first we get all navigators
      getNavigatorList({
        siteID: Common.siteID,
        navigatorID: id,
        sortTypeOrder: 1,
        pageNumber: 999,
      }, res => {
        res.data.body.data.rows.forEach(item => {
          //then if one navigator's id equals the id send to this component,we use this navigator item
          if (item.navigatorID === this.navigatorID) { this.naviName = item.name; this.naviShortName = item.shortName }
        });
      });
    }
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 900px) {
  .home {
    div.CompanyValue {
      margin-bottom: -10px;

      .title_ZH,
      .title_EN {
        font-size: 18px;
      }
    }

    div.companyValue {
      flex-direction: column;
      margin-top: 20px;

      .CivilAviationOperations {
        width: 100%;
        margin: 10px 0;

        .listImage {
          img {
            height: 160px;
          }
        }

        .description_ZH,
        .description_EN {
          font-size: 16px;
          margin: 10px auto;
          font-weight: 600;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }

        .content_ZH,
        content_EN {
          font-size: 14px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.home {
  div.CompanyValue {
    margin-bottom: -2.125rem;

    .title_ZH,
    .title_EN {
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #404040;
      letter-spacing: 0.125rem;
    }

    .title_EN {
      font-family: PingFang-SC-Heavy, PingFang-SC;
      font-weight: 800;
    }
  }

  div.companyValue {
    margin-top: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    >div {
      width: calc(50% - 2.5rem);
      margin: 0 2.5rem 2.5rem 0;
      border: 1px solid #ddd;
      padding: 10px;

      &:nth-child(2n) {
        margin-right: 0;
      }

      .listImage {
        img {
          width: 100%;
          height: 350px;
          object-fit: cover;
        }
      }

      .description_ZH,
      .description_EN {
        font-size: 20px;
        margin: 1.875rem auto;
        font-weight: 600;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* 控制显示的行数 */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }

      .content_ZH,
      .content_EN {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        /* 控制显示的行数 */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
    }
  }

}
</style>