<template>
  <div class="app">
    <Header/>
    <main ref="main">
      <router-view :key="router.fullPath"/>
    </main>
    <div class="right-btns">
      <ul class="right-btn">
        <li @click="openContact">联系我们</li>
        <li @click="openMessageBox">在线咨询</li>
        <li @click="webClick">环信</li>
        <div class="onlineBox" v-if="data.onlineShow">
          <div class="companyBox flex-row align-center">
            <img src="../public/iconTitle.png">
            <div v-if="data.companyInfo.siteCompanyName">{{ data.companyInfo.siteCompanyName.value }}</div>
          </div>
          <div class="messageBox" id="chatBox-content-demo">
            <div class="messageItem" v-for="(item,index) in data.allMessageObj" :key="index">
              <div class="flex-row align-center" v-if="item.memberID !== data.memberID">
                <span class="userImg">服</span>
                <div class="contentBox">
                  <div class="flex-row">
                    <div class="memberName">{{ item.memberName }}</div>
                    <div>{{ item.sendTimeString }}</div>
                  </div>
                  <div class="message-content message-left">{{ item.name }}</div>
                </div>
              </div>
              <div class="flex-row align-center right" v-if="item.memberID == data.memberID">
                <div class="contentBox" style="margin: 0 10px 0 0;">
                  <div class="flex-row" style="justify-content: flex-end">
                    <div>{{ item.sendTimeString }}</div>
                    <div class="memberName" style="margin-left: 10px">{{ item.memberName }}</div>
                  </div>
                  <div class="message-content message-right">{{ item.name }}</div>
                </div>
                <span class="userImg">我</span>
              </div>
            </div>
          </div>
          <div class="bottomBox">
            <div>
              <textarea placeholder="输入内容……" v-model="data.content"></textarea>
            </div>
            <div @click="sendMessage">发送</div>
          </div>

        </div>
        <div class="contactBox flex-column align-center justify-between" v-if="data.contactShow">
          <div class="contactItem flex-column align-center">
            <div>QQ客服<span>（24小时在线）</span></div>
            <div class="imgBox">
              <a target="_blank"
                 :href="'http://wpa.qq.com/msgrd?v=3&uin='+data.companyInfo.onlineQQ.value+'&site=qq&menu=yes'">
                <img src="../public/wanhuanguqqkefu.png" alt="点击这里给我发消息"
                     title="点击这里给我发消息"/>
              </a>
            </div>
          </div>
          <div class="contactItem flex-column align-center">
            <div>微信客服<span>（09:00 / 18:00）</span></div>
            <div class="imgBox">
              <img v-if="data.companyInfo.onlineWeiXinQRCode" :src="data.companyInfo.onlineWeiXinQRCode.value">
            </div>
          </div>
          <div class="contactItem flex-column align-center">
            <div>热线电话</div>
            <div class="telBox">{{ data.companyInfo.fuwuTel.value }}</div>
          </div>
        </div>
      </ul>
    </div>

    <el-dialog
        title="环信"
        v-model="webData.webDialogVisible"
        :close-on-click-modal="false"
        width="800px"
    >
      <div id='msgBody' ref="scrollRef">
        <div v-for='item in webData.webMessageList'>
          <div v-if="item.from==webData.webID?true:false">
            <div style="text-align: center;">{{ formatDate(item.time) }}</div>
            <div class="msgRight">
              <p class="msgName" style="color: #00aaff;">我</p>
              <span v-if="item.type=='txt'" class="msgText">{{ item.msg }}</span>
              <el-image @click="setImgList(item.url)" :preview-src-list="webData.imgList" class="huanxinImgBox"
                        v-if="item.type=='img'" :src="item.url"></el-image>
              <span v-if="item.type=='loc'" class="msgText">发送了一个位置消息{{ item.buildingName }}<span
                  style="color: red;cursor: pointer" @click="showLocMeeeage(item.lat,item.lng,item.addr)">（点击查看）</span></span>
            </div>
            <br style="clear: both;"/>
          </div>
          <div v-else>
            <div style="text-align: center;">{{ formatDate(item.time) }}</div>
            <div class="msgLeft">
              <p style="color: #00aaff;" class="msgName">客服</p>
              <!--              <p style="color: #00aaff;" class="msgName">{{webData.toWebName}}</p>-->
              <span v-if="item.type=='txt'" class="msgText">{{ item.msg }}</span>
              <el-image @click="setImgList(item.url)" :preview-src-list="webData.imgList" class="huanxinImgBox"
                        v-if="item.type=='img'" :src="item.url"></el-image>
              <span v-if="item.type=='loc'" class="msgText">发送了一个位置消息{{ item.buildingName }}<span
                  style="color: red;cursor: pointer" @click="showLocMeeeage(item.lat,item.lng,item.addr)">（点击查看）</span></span>
            </div>
          </div>
        </div>
      </div>
      <div id='msgFooter'>
        <el-input :disabled=" webData.huanxinImgList.length !== 0 || webData.mapShow" type="textarea" :rows="3"
                  v-model="webData.webInputMessage" @keyup.enter.native="webSendMessage()"
                  style="outline: none;"></el-input>
        <div class="btnBox" style="margin-top: 20px">
          <el-upload
              list-type="picture-card"
              ref="videoURLUploadRef"
              :action="data.scurl"
              :on-success="huanxinImgSuccess"
              :on-remove="huanxinRemove"
              :file-list="webData.huanxinImgList"
              :limit="1"
              style="margin-right: 10px"
          >
            <span style="font-size: 40px">+</span>
          </el-upload>
          <img src="./assets/img/loc.png" style="width:40px;height:40px;margin: 0 10px;cursor: pointer"
               @click="sendLoc">
          <el-button class="sendBnt" type="primary" @click="webSendMessage()">发送</el-button>
        </div>

        <div v-if="webData.mapShow" style="margin-top: 10px">
          <div class="mapIpt">
            <el-input v-model="webData.mapInput" placeholder="请输入地址" style="margin-right: 10px"></el-input>
            <el-button class="sendBnt" type="primary" @click="search">搜索</el-button>
          </div>
          <p style="margin: 10px 0;text-align: center">当前地址：{{ webData.mapInput }}<span
              v-if="webData.mapX && webData.mapY">（X:{{ webData.mapX }} Y:{{ webData.mapY }}）</span></p>
          <div class="mapBox">
            <QQMap ref="QQMapRef" @qqGetLocation="qqGetLocation"/>
          </div>
        </div>
      </div>
    </el-dialog>

    <Footer/>
  </div>
</template>

<script setup>
import {nextTick, reactive, inject, ref, onMounted, onBeforeUnmount, getCurrentInstance} from "vue";
import {useRoute} from 'vue-router'
import {useStore} from 'vuex';
import {
  getSiteParameterList,
  sendSMSVerifyCode,
  memberPhoneRegister,
  memberLogin,
  getSiteServicerList,
  getServiceEventList,
  submitOneServiceEvent,
  getToMeTalkingMessageList,
  getMeToTalkingMessageList,
  sendOneTalkMemberMessage,
  applyOneShopSiteService,
  getMemberGroupList,
  updateOneMemberGroup
} from "@/common/api";
import Header from "./components/header/header.vue"
import Footer from "./components/footer/footer.vue"
import QQMap from "./components/qqMap";

import websdk from 'easemob-websdk'
import {conn} from '@/utils/webIM/initWeb'
import {formatDate} from '@/utils/util'

const {proxy, ctx} = getCurrentInstance()

let Common = inject('Common')
let main = ref()

let QQMapRef = ref(null)
// onMounted(()=>{
//   main.value.style.minHeight = `${document.documentElement.clientHeight-473-80}px`
// })
// window.addEventListener('resize', ()=>{
//   main.value.style.minHeight = `${document.documentElement.clientHeight-473-80}px`
// });
const store = useStore();
const router = useRoute();

const data = reactive({
  memberID: '',
  companyInfo: {},
  contactShow: false,
  onlineShow: false,
  content: '',
  allMessage: [],
  allMessageObj: [],
  memberPhone: '',
  authCode: '',
  servicerList: [],
  servicerEventList: [],
  serviceEventID: '',
  memberGroupID: '',
  memberGroupName: '',
  groupIMID: '',
  timer: null,
  scurl: process.env.VUE_APP_BASE_API + 'interface-server/api/' + "uploadOneFileToQiniu.json?sessionID=" + localStorage.getItem('sessionID') + "&fileBucketID=8a2f462a81132165018113e0862c01b1",


})

const webData = reactive({
  //环信
  webDialogVisible: false,
  webMessageList: [],
  webInputMessage: '',

  groupID: '',

  webID: '',
  toWebID: '',
  toWebName: '',

  imgList: [''],
  huanxinImgList: [],

  mapShow: false,
  mapInput: '',
  mapX: '',
  mapY: ''

})
let scrollRef = ref(null);

store.dispatch('user/getUserInfo');
store.dispatch('nav/getNavList');
store.dispatch('nav/getSwiper');
store.dispatch('nav/getNavList3')

const getSiteParameter = () => {
  getSiteParameterList({
    siteID: Common.siteID,
  }, res => {
    if (res.data.header.code === 0) {
      let ParameterList = res.data.body.data
      //console.log(ParameterList,'站点参数')
      let companyInfo = {};
      ParameterList.forEach((item) => {
        // console.log(item)
        if (item.code == "webSiteCertificate") {
          companyInfo.webSiteCertificate = item
        }
        if (item.code == 'companyAddress') {
          companyInfo.companyAddress = item
        }
        if (item.code == "siteCompanyName") {
          companyInfo.siteCompanyName = item
        }
        if (item.code == "siteCompanyTel") {
          companyInfo.siteCompanyTel = item
        }
        if (item.code == "siteCompanyEmail") {
          companyInfo.siteCompanyEmail = item
        }
        if (item.code == "siteWeixinQrcodeUrl") {
          companyInfo.siteWeixinQrcodeUrl = item
        }
        if (item.code == "weixinLoginQcodePage") {
          companyInfo.weixinLoginQcodePage = item
        }
        if (item.code == 'supportCompanyEmail') {
          companyInfo.supportCompanyEmail = item
        }
        if (item.code == 'shopServiceMail') {
          companyInfo.shopServiceMail = item
        }
        if (item.code == 'scientificServiceEmail') {
          companyInfo.scientificServiceEmail = item
        }
        if (item.code == "fuwuTel") {
          companyInfo.fuwuTel = item
        }
        if (item.code == 'onlineQQ') {
          companyInfo.onlineQQ = item
        }
        if (item.code == 'onlineWeiXinQRCode') {
          companyInfo.onlineWeiXinQRCode = item
        }
      })
      data.companyInfo = companyInfo
      localStorage.setItem('siteParams', JSON.stringify(companyInfo))
    }
  })
}

const allMessageInfo = (messageObj) => {
  let allMessage = data.allMessage;
  let allMessageObj = data.allMessageObj;
  //循环遍历数组
  for (var j = 0; j <= messageObj.length; j++) {
    if (messageObj[j] == null) {
      continue;
    }
    let sendMemberMessage = messageObj[j].id;
    if (allMessage.length == 0) {
      allMessage[0] = sendMemberMessage;
      allMessageObj[0] = messageObj[j];
      continue;
    }
    for (var i = 0; i < allMessage.length; i++) {
      //判断addNum 是否与数组中数字重复
      if (allMessage[i] == sendMemberMessage) {
        // flag = true;
        //如有重复，跳出循环
        break;
      }
      //保证数组能够循环一遍后且不重复，再添加元素
      if (i == allMessage.length - 1) {
        //添加元素
        allMessage[i + 1] = sendMemberMessage;
        allMessageObj[allMessageObj.length] = messageObj[j];
        // data.scroll = allMessageObj.length * 100 + 100
      }
    }
  }
  allMessageObj = allMessageObj.sort(function (obj1, obj2) {
    return obj2.sendTimeString > obj1.sendTimeString ? -1 : 1;
  })
  data.allMessageObj = allMessageObj
}

//tome聊天记录
const getToMe = () => {
  getToMeTalkingMessageList({
    companyID: Common.companyID,
    sessionID: localStorage.getItem('sessionID'),
    //memberID: this.data.memberID,
    serviceEventID: data.serviceEventID,
    beginTime: '2024-01-18',
    pageNumber: '99'
  }, res => {
    if (res.data.header.code == 0) {
      let list = res.data.body.data.rows
      allMessageInfo(list)
    }
  })
}
//meto聊天记录
const getMeTo = () => {
  getMeToTalkingMessageList({
    companyID: Common.companyID,
    sessionID: localStorage.getItem('sessionID'),
    serviceEventID: data.serviceEventID,
    beginTime: '2024-01-18',
    pageNumber: '99',
    sendMemberID: localStorage.getItem('memberID'),
    sendToMemberID: data.servicerList[0].memberID
  }, res => {
    if (res.data.header.code == 0) {
      let list = res.data.body.data.rows
      allMessageInfo(list)
    }
  })
}

const getMessage = () => {
  getToMe()
  getMeTo()
}
const openContact = ()=>{
  data.contactShow = !data.contactShow
  //关掉另一个窗口
  if(data.onlineShow)
    data.onlineShow = !data.onlineShow
}

const openMessageBox = () => {
  //关掉另一个窗口
  if(data.contactShow)
    data.contactShow = !data.contactShow
  data.onlineShow = !data.onlineShow
  if (data.onlineShow) {
    if (!data.servicerList[0]) return proxy.$message.warning('当前没有客服在线')
    getMessage();
    data.timer = setInterval(() => {
      getMessage();
    }, 5000)
  } else {
    clearInterval(data.timer)
  }
}

const creatServiceEvent = () => {
  if (data.servicerList.length == 0) return
  submitOneServiceEvent({
    sessionID: localStorage.getItem('sessionID'),
    shopID: Common.shopID,
    companyID: Common.companyID,
    memberID: data.servicerList[0].memberID
  }, res => {
    if (res.data.header.code == 0) {
      data.serviceEventID = res.data.body.serviceEventID
    }
  })
}

const creatMemberGroup = () => {
  return new Promise((resolve) => {
    applyOneShopSiteService({
      sessionID: localStorage.getItem('sessionID'),
      shopID: Common.shopID,
    }, res => {
      if (res.data.header.code == 0) {
        data.serviceEventID = res.data.body.serviceEventID
        data.memberGroupID = res.data.body.memberGroupID
        resolve(true)
      }
    })
  })
}

//获取客服事件列表
const getServiceEvent = () => {
  getServiceEventList({
    sessionID: localStorage.getItem('sessionID'),
    applicationID: Common.applicationID,
    companyID: Common.companyID,
    memberID: data.servicerList[0].memberID,
    askMemberID: localStorage.getItem('memberID'),
    shopID: Common.shopID,
    sortTypeTime: 1
  }, res => {
    let resData = res.data.body.data.rows || [];
    data.servicerEventList = resData
    if (resData[0] && !resData[0].endTime) {
      data.serviceEventID = resData[0].serviceEventID
      //获取聊天记录
      getMessage();
    } else {
      creatServiceEvent()
    }
  })
}

//获取客服列表
const getServicerList = () => {
  getSiteServicerList({
    applicationID: Common.applicationID,
    companyID: Common.companyID,
    shopID: Common.shopID,
    isOnline: 1,
    sortTypeOnline: 1
  }, async res => {
    data.servicerList = res.data.body.data.rows || [];
    if (data.servicerList.length == 0) return
    //获取客服事件列表
    getServiceEvent();

    // let resGroup = await getMemberGroup()
    // if (!resGroup) creatServiceEvent();
  })
}

const getPhone = () => {
  const prefixArray = new Array("130", "131", "132", "133", "135", "137", "138", "170", "187", "189");
  let i = Math.floor(10 * Math.random());
  let prefix = prefixArray[i];
  for (let j = 0; j < 8; j++) {
    prefix = prefix + Math.floor(Math.random() * 10);
  }
  return prefix;
}

const getCode = () => {
  data.memberPhone = getPhone()
  localStorage.setItem('memberPhone', data.memberPhone)
  // document.cookie = 'memberPhone=' + this.memberPhone;
  //获取短信验证码
  sendSMSVerifyCode({
    deviceID: Common.deviceID,
    siteID: Common.siteID,
    phone: data.memberPhone,
    special: '666',
    sendType: '0'
  }, res => {
    if (res.data.header.code == 0) {
      data.authCode = res.data.body.authCode
      register();
    }
  })
}

//注册
const register = () => {
  memberPhoneRegister({
    deviceID: Common.deviceID,
    siteID: Common.siteID,
    password: '999999',
    phone: data.memberPhone,
    verifyCode: data.authCode
  }, res => {
    if (res.data.header.code == 0) {
      let member = res.data.body
      data.memberID = member.memberID
      localStorage.setItem('memberID', member.memberID)
      localStorage.setItem('sessionID', member.sessionID)
      localStorage.setItem('member', JSON.stringify(member))
      getServicerList();
      creatServiceEvent();
      data.scurl=process.env.VUE_APP_BASE_API + 'interface-server/api/' + "uploadOneFileToQiniu.json?sessionID=" + localStorage.getItem('sessionID') + "&fileBucketID=8a2f462a81132165018113e0862c01b1"
    }
  })
}

//登录
const login = () => {
  let memberPhone = localStorage.getItem('memberPhone')
  if (memberPhone) {
    memberLogin({
      deviceID: Common.deviceID,
      siteID: Common.siteID,
      loginName: memberPhone,
      password: '999999',
    }, res => {
      if (res.data.header.code == 0) {
        let obj = res.data.body;
        data.memberID = obj.memberID
        localStorage.setItem('memberID', obj.memberID)
        localStorage.setItem('sessionID', obj.sessionID)
        data.scurl=process.env.VUE_APP_BASE_API + 'interface-server/api/' + "uploadOneFileToQiniu.json?sessionID=" + localStorage.getItem('sessionID') + "&fileBucketID=8a2f462a81132165018113e0862c01b1"

      } else {
        getCode()
      }
    })
  } else {
    getCode()
  }
}

const sendMessage = () => {
  if (!data.servicerList[0]) return proxy.$message.warning('当前没有客服在线')
  if (!data.content) return
  sendOneTalkMemberMessage({
    // memberGroupID:data.memberGroupID,
    sendToMemberID: data.servicerList[0].memberID,
    companyID: Common.companyID,
    shopID: Common.shopID,
    serviceEventID: data.serviceEventID,
    pageNumber: '999999999',
    name: data.content,
    sessionID: localStorage.getItem('sessionID'),
    talkType: '1',
  }, res => {
    if (res.data.header.code == 0) {
      data.content = ''
      getMeTo()
    }
  })
}


const getMemberGroup = () => {
  return new Promise((resolve, reject) => {
    getMemberGroupList({
      sessionID: localStorage.getItem('sessionID'),
      headMemberID: localStorage.getItem('memberID'),
    }, res => {
      if (res.data.header.code == 0) {
        if (res.data.body.data.rows.length === 0) resolve(false)
        data.memberGroupID = res.data.body.data.rows[0].memberGroupID
        data.memberGroupName = res.data.body.data.rows[0].name
        data.groupIMID = res.data.body.data.rows[0].huanxinID
        resolve(true)
      } else {
        reject(false)
      }
    })
  })
}


//环信
// 集成收到文本信息方法
const webGetMessage = () => {
  conn.addEventHandler("eventName", {
    onConnected: function (message) {
      console.log('用户已上线') // 连接成功
    },
    onTextMessage: function (message) {
      console.log('收到文本信息', message)
      let date = formatDate(new Date(new Date().getTime()), 'yyyy-MM-dd hh:mm');
      if (message.to != webData.groupID) return
      let value = {}
      // 普通文本信息
      value = {
        type: 'txt',
        msg: message.msg,
        to: message.to,
        from: message.from,
        time: date
      }
      webData.webMessageList.push(value) // 添加到聊天信息数组中
      console.log(webData.webMessageList, 'webData.webMessageList')
      nextTick(() => {
        scrollRef.value.scrollTop = scrollRef.value.scrollHeight;
      });
    },
    onImageMessage: function (message) {
      console.log('收到图片信息', message)
      if (message.to != webData.groupID) return
      let date = formatDate(new Date(new Date().getTime()), 'yyyy-MM-dd hh:mm');
      let value = {}
      // 普通文本信息
      value = {
        type: 'img',
        url: message.url,
        to: message.to,
        from: message.from,
        time: date
      }
      webData.webMessageList.push(value) // 添加到聊天信息数组中
      nextTick(() => {
        scrollRef.value.scrollTop = scrollRef.value.scrollHeight;
      })
    },
    onLocationMessage: function (message) {
      console.log('收到位置信息', message);
      if (message.to != webData.groupID) return
      webData.webMessageList.push(message) // 添加到聊天信息数组中
      nextTick(() => {
        scrollRef.value.scrollTop = scrollRef.value.scrollHeight;
      })
    },
    onerror(err) {
      console.log(err, '连接错误')
    }
  });
  // conn.addEventHandler("handlerId", {
  //   onConnected: () => {
  //     console.log("onConnected");
  //   },
  //   onDisconnected: () => {
  //     console.log("onDisconnected");
  //   },
  //   onTokenWillExpire: () => {
  //     console.log("onTokenWillExpire");
  //   },
  //   onTokenExpired: () => {
  //     console.log("onTokenExpired");
  //   },
  // });
}

const createMemberGroupIM = () => {
  return new Promise((resolve) => {
    let option = {
      data: {
        groupname: localStorage.getItem('memberPhone') + '的客服群',
        desc: localStorage.getItem('memberPhone') + '的客服群',
        members: [webData.toWebID],
        public: true,
        approval: false,
        allowinvites: true,
        inviteNeedConfirm: false,
        maxusers: 200,
        ext: "group detail extensions",
      },
    };
    conn.createGroup(option).then((res) => {
          console.log(res.data.groupid)
          webData.groupID = res.data.groupid
          updateOneMemberGroup({
            sessionID: localStorage.getItem('sessionID'),
            memberGroupID: data.memberGroupID,
            huanxinID: res.data.groupid
          }, resSelf => {
            resolve(true)
          })
        }
    ).catch(err => {
      console.log(err, '创建群组失败')
    })
  })
}

const getHistoryMessagesIM = async () => {
  if (!webData.groupID) await createMemberGroupIM();
  let options = {
    // 对方的用户 ID 或者群组 ID 或聊天室 ID。
    targetId: webData.groupID,
    // 每页期望获取的消息条数。取值范围为 [1,50]，默认值为 20。
    pageSize: 50,
    // 查询的起始消息 ID。若该参数设置为 `-1`、`null` 或空字符串，从最新消息开始。
    cursor: -1,
    // 会话类型：（默认） `singleChat`：单聊；`groupChat`：群聊。
    chatType: "groupChat",
    // 消息搜索方向：（默认）`up`：按服务器收到消息的时间的逆序获取；`down`：按服务器收到消息的时间的正序获取。
    searchDirection: "down",
    msgTypes: ['txt', 'img'],
  };
  conn.getHistoryMessages(options)
      .then((res) => {
        // 成功获取历史消息。
        console.log(res, '环信历史消息');
        webData.webMessageList = res.messages
        nextTick(() => {
          scrollRef.value.scrollTop = scrollRef.value.scrollHeight;
        });
      })
      .catch((e) => {
        console.log(e, '获取历史消息失败')
        if (!conn.isOpened()) return loginIM()
        // 获取失败。
      });
}

const registerIM = () => {
  conn.registerUser({
    username: webData.webID,
    password: '111111'
  })
      .then((res) => {
        console.log(res, '注册成功');
        loginIM()
      })
      .catch(err => {
        console.log(err, '注册失败')
      })
}

const loginIM = () => {
  if (conn.isOpened()) return console.log('已经是登录状态了')
  var options = {
    user: webData.webID,
    pwd: '111111',
    appKey: WebIM.config.appkey,
    success: function (res) {
      localStorage.setItem('webToken', res.access_token)
      localStorage.setItem('webUser', JSON.stringify(res.user))
      console.log('成功', res)
      webGetMessage();
      setTimeout(() => {
        getHistoryMessagesIM()
      }, 200)
      // proxy.$message.success('登录成功')
    },
    error: function (err) {
      console.log('失败', err)
      if (err.data.extraInfo.errDesc == "user not found") {
        console.log('调用注册方法')
        registerIM();
      } else {
        proxy.$message.error('登录失败:' + err.data.message)
      }
    }
  };
  conn.open(options);
}

const webClick = async () => {
  //关掉两个窗口
  if(data.contactShow)
    data.contactShow = !data.contactShow
  if(data.onlineShow)
  data.onlineShow = !data.onlineShow

  webData.mapShow = false
  webData.webDialogVisible = true
  if (!data.servicerList[0]) return proxy.$message.warning('当前没有客服在线')
  webData.webID = localStorage.getItem('memberID')
  webData.toWebID = data.servicerList[0].memberID
  webData.toWebName = data.servicerList[0].name
  // let token = localStorage.getItem('webToken')
  let res = await getMemberGroup();
  if (!res) await creatMemberGroup();
  webData.groupID = data.groupIMID
  console.log(webData.groupID,'webData.groupID')
  console.log(!conn.isOpened(),'!conn.isOpened()')
  if (!conn.isOpened()) {
    loginIM()
  } else {
    webGetMessage();
    getHistoryMessagesIM();
  }
}

//成功发送消息，进行消息加入到聊天信息数组中
const sendMessageSuccessful = async (data, toID, type) => {
  let userMsg = {};
  userMsg.to = toID;
  userMsg.from = webData.webID;
  // userMsg.msg=data;
  if (type == 'img') {
    userMsg.url = data
  } else if (type == 'txt') {
    userMsg.msg = data
  } else if (type == 'loc') {
    userMsg = {}
    userMsg = data
  }
  userMsg.time = formatDate(new Date(new Date().getTime()), 'yyyy-MM-dd hh:mm');
  userMsg.type = type;
  //存储信息
  webData.webMessageList.push(userMsg);
  await nextTick();
  scrollRef.value.scrollTop = scrollRef.value.scrollHeight;
}

const sendPrivateUrlImg = (imgUrl) => {
  let option = {
    chatType: "groupChat",
    // 消息类型。
    type: "img",
    // 图片文件的 URL 地址。
    url: imgUrl,
    // 消息接收方：单聊为对方用户 ID，群聊和聊天室分别为群组 ID 和聊天室 ID。
    to: webData.groupID,
  };
  // 创建一条图片消息。
  let msg = WebIM.message.create(option);
  //  调用 `send` 方法发送该图片消息。
  conn.send(msg).then(res => {
    console.log('成功发送消息');
    webData.huanxinImgList = []
    // that.sendMemberMessage('IM')
    sendMessageSuccessful(imgUrl, webData.groupID, 'img');
  }).catch(e => {
    console.log("发送消息失败", e);
    proxy.$message.warning('发送消息失败')
  })
}
const sendLocMsg = (lat, lng) => {
  if (!lat || !lng) return proxy.$message.error('位置信息错误')
  var that = this
  let option = {
    chatType: "groupChat",
    type: "loc",
    to: webData.groupID,
    addr: webData.mapInput,
    buildingName: webData.mapInput,
    lat, // 纬度
    lng, // 经度
  };
  let msg = WebIM.message.create(option);
  conn.send(msg).then((res) => {
    console.log("发送消息成功", res);
    sendMessageSuccessful(res.message, webData.groupID, 'loc');
  }).catch((e) => {
    console.log("发送消息失败", e);
    proxy.$message.warning('发送消息失败')
  });
}

const webSendMessage = () => {
  if (webData.mapShow) return sendLocMsg(webData.mapX, webData.mapY)
  if (webData.huanxinImgList.length !== 0) return sendPrivateUrlImg(webData.huanxinImgList[0].url)

  if (!webData.webInputMessage || !(webData.webInputMessage.replace(/&nbsp;|\s/g, ''))) {
    proxy.$message.error('发送内容不能为空!');
    return;
  }
  let contentMsg = webData.webInputMessage;
  let toID = webData.groupID;   //收信息群组id
  let id = conn.getUniqueId();                 // 生成本地消息id
  let msg = new WebIM.message('txt', id);      // 创建文本消息
  msg.set({
    msg: contentMsg,                  // 消息内容
    to: toID,                          // 接收消息对象（用户id）
    chatType: 'groupChat',                  // 设置为单聊
    success: function (id, serverMsgId) {
      console.log('成功发送消息');
      sendMessageSuccessful(contentMsg, toID, 'txt');
      sendOneTalkMemberMessage({
        memberGroupID: data.memberGroupID,
        // sendToMemberID: data.servicerList[0].memberID,
        companyID: Common.companyID,
        shopID: Common.shopID,
        serviceEventID: data.serviceEventID,
        pageNumber: '999999999',
        name: contentMsg,
        sessionID: localStorage.getItem('sessionID'),
        talkType: '1',
      }, res => {
      })
    },
    fail: function (e) {
      console.log("发送消息失败");
    }
  });
  conn.send(msg.body);
  webData.webInputMessage = null;
}

const showLocMeeeage = (lat, lng, addr) => {
  console.log(lat, lng, addr)
  webData.mapInput = addr
  if (webData.mapShow) {
    QQMapRef.value.setInitChange(lat, lng, 16)
  } else {
    webData.mapShow = true
    nextTick(() => {
      QQMapRef.value.reserMap(lat, lng, 16)
    })
  }

}

const huanxinImgSuccess = (response, file, fileList) => {
  let obj = {url: response.body.url}
  webData.huanxinImgList.push(obj)
}

const huanxinRemove = () => {
  webData.huanxinImgList = []
}

const setImgList = (url) => {
  webData.imgList[0] = url
}

const sendLoc = () => {
  webData.mapInput = ''
  webData.mapX = ''
  webData.mapY = ''
  webData.mapShow = !webData.mapShow
  if (webData.mapShow) {
    nextTick(() => {
      QQMapRef.value.reserMap(39.908823, 116.39747, 16)
    })
  }
}

const search = () => {
  QQMapRef.value.clickIpt(webData.mapInput)
}

const qqGetLocation = (mapX, mapY) => {
  if (!mapX || !mapY) return proxy.$message.error('搜索不到该地址')
  console.log(mapX, mapY, 'qqGetLocation')
  webData.mapX = mapX
  webData.mapY = mapY
}


getSiteParameter();
onMounted(() => {
  let memberID = localStorage.getItem('memberID')
  let sessionID = localStorage.getItem('sessionID')
  data.memberID = memberID
  if (!sessionID && !memberID) {
    login();
  }else{
    getServicerList();
  }

})

onBeforeUnmount(() => {
  clearInterval(data.timer)
  data.timer = null;
  conn.close();
})
</script>

<style lang="scss">
#app,
html,
body,
.app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.app {
  main {
    width: 100%;
  }
}

.right-btns {
  position: fixed;
  z-index: 999;
  right: 1.5%;
  top: 40%;

  .right-btn {
    width: 45px;

    > li {
      width: 100%;
      //height: 100px;
      cursor: pointer;
      text-align: center;
      font-size: 16px;
      color: #ffffff;
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 20px;
    }

    li:nth-child(1) {
      background-image: linear-gradient(#F2CE23, #FC9780);
    }

    li:nth-child(2) {
      background-image: linear-gradient(#5CCCFD, #1669EB);
    }

    li:nth-child(3) {
      background-image: linear-gradient(#5CCCFD, #1669EB);
    }
  }
}

.contactBox {
  width: 300px;
  //height: 200px;
  padding: 20px;
  position: absolute;
  right: 50px;
  top: -50%;
  background-color: #FFFFFF;
  border-radius: 10px;

  .contactItem {
    > div:first-child {
      font-weight: bold;
      font-size: 18px;
      //text-align: center;
      margin: 10px 0;
    }

    .telBox {
      border-radius: 10px;
      color: #3B88FD;
      text-align: center;
      padding: 10px 20px;
      background-color: #EFEFEF;
    }

    .imgBox {
      width: 200px;
      height: 200px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.onlineBox {
  width: 400px;
  //height: 200px;
  padding: 20px;
  position: absolute;
  right: 50px;
  top: -50%;
  background-color: #FFFFFF;
  border-radius: 10px;

  .companyBox {
    img {
      width: 30px;
      margin-right: 10px;
    }
  }

  .messageBox {
    width: 100%;
    height: 250px;
    margin-top: 10px;
    padding: 10px;
    border-radius: 10px;
    overflow-y: scroll;
    background-color: #EFEFEF;

    .messageItem {
      font-size: 14px;
      margin-top: 10px;

      .right {
        justify-content: flex-end;
      }

      .contentBox {
        //width: 100%;
        flex: 1;
        margin-left: 10px;

        .memberName {
          font-weight: bold;
        }

        .message-content {
          display: inline-block;
          margin-top: 3px;
          padding: 5px;
          background-color: #ffffff;
          border-radius: 0 5px 5px 5px;
        }

        .message-right {
          float: right;
          border-radius: 5px 0 5px 5px;
        }
      }

      .userImg {
        display: inline-block;
        color: #ffffff;
        width: 30px;
        height: 30px;
        text-align: center;
        border-radius: 50%;
        line-height: 30px;
        background-color: #B4B4B4;
      }
    }
  }

  ::-webkit-scrollbar {
    /* 设置水平滚动条的高度，垂直滚动的宽度 */
    width: 0px;
  }

  .bottomBox {
    > div:first-child {
      width: 100%;
      margin: 10px 0;

      > textarea {
        width: 100%;
        height: 80px;
        outline: none;
        resize: none;
        border: 1px solid #D3D3D3;
        border-radius: 10px;
        padding: 10px;
      }
    }

    > div:last-child {
      float: right;
      display: inline-block;
      cursor: pointer;
      color: #136DFF;
      text-align: center;
      padding: 5px 10px;
      border-radius: 10px;
      border: 1px #136DFF solid;
    }
  }
}

#msgBody {
  height: 500px;
  background-color: #EFEFEF;
  overflow: auto;
  padding: 20px;
}

#msgFooter {
  margin-top: 20px;

  > .btnBox {
    display: flex;
    justify-content: end;
    align-items: center;
  }
}

.sendBnt {
  //margin-top: 20px;
}

.msgName {
  font-weight: bold;
}

.msgText {
  display: inline-block;
  margin-top: 3px;
  padding: 5px 10px;
  background-color: #ffffff;
  //border-radius: 0 5px 5px 5px;
  border-radius: 5px;
}

.msgLeft {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.msgRight {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.huanxinImgBox {
  width: 150px;
}

.mapIpt {
  display: flex;
  align-items: center;
}

.mapBox {
  width: 100%;
  height: 300px;
}
</style>
