<script setup>
import {getNavigatorList, getNavigatorSimpleArticleList} from "@/common/api";
import Common from "@/common/common";
import {ref, watchEffect} from "vue";
import store from "@/store";
import Breadcrumb from "@/components/Breadcrumb/index.vue";
import {ElMessage} from "element-plus";
import router from "@/router";
import blockA from "@/components/articleBlock/blockA.vue";
import blockB from "@/components/articleBlock/blockB.vue";
const windowWidth = ref(window.innerWidth);
const isSmallScreen = ref(windowWidth.value < 900);
watchEffect(() => {
  const handleResize = () => {
    windowWidth.value = window.innerWidth;
    isSmallScreen.value = windowWidth.value < 900;
  };

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
});
let navigatorArr = []; // 存放name，navigatorID
//点击企业使命 添加class
const activeIndex = ref('0')
const toggleActive = (index, item) => {
  activeIndex.value = index
  toPath(item)
}
// 点击四大优势 添加class
const FourIndex = ref('0')
const FourActive = (index, item) => {
  FourIndex.value = index
  toPath(item)
}
//公司业务 轮播
const itemIndex = ref('0');
// 上一页
const prevItem = () => {
  if (itemIndex.value > 0) {
    itemIndex.value--;
  } else {
    itemIndex.value = CorporateBusiness.value.length - 1
  }
}
// 下一页
const nextItem = () => {
  if (itemIndex.value < CorporateBusiness.value.length - 1) {
    itemIndex.value++;
  } else {
    itemIndex.value = '0'
  }
}
// 公司业务
let CorporateBusiness = ref([])
const data = ref({
  companyProfile: [],//公司简介
  BusinessTypes: [], //公司业务
  Background: [], //企业背景
  Corporate: [],//企业使命
  CompanyValue: [],//公司价值
  Fouradvantages: [],//四大优势
  DevelopmentGoals: [],//"发展目标"
});
const tasks = [
  '公司简介',
  '公司业务',
  '企业背景',
  '企业使命',
  '公司价值',
  '四大优势',
  '发展目标',
].map((name, index) => ({name, list: Object.keys(data.value)[index]}));
const queryArticleList = () => {
  return new Promise((resolve, reject) => {
    getNavigatorList({
      siteID: Common.siteID,
      navigatorID: Common.navIdList.RecommendedNavigationId,
      sortTypeOrder: 1,
      pageNumber: 999,
    }, res => {
      res.data.body.data.rows.forEach(item => {
        navigatorArr.push({
          name: item.name,
          nameEN: item.shortName, 
          navigatorID: item.navigatorID, 
          isLock:item.isLock});
        //我也不知道为什么store/nav.js里没有把首页导航加进缓存，正好就从这里加进去好了
        store.state.nav.navList.push({name: item.name, navigatorID: item.navigatorID,shortName:item.shortName});
      });
      resolve(navigatorArr); // 异步操作完成后解析Promise并返回navigatorArr数组
    });
  });
};
const contentList = async () => {
  const result = await queryArticleList();
  const requests = tasks.map(task => {
    const item = result.find(item => item.name === task.name);
    if (item) {
      return new Promise(resolve => {
        getNavigatorSimpleArticleList({
          navigatorID: item.navigatorID,
          sortTypeOrder: 1
        }, res => {
          resolve({list: task.list, dataRows: res.data.body.data.rows});
        });
      });
    }
  });
  const responses = await Promise.all(requests);
  responses.forEach(response => {
    if (response) {
      data.value[response.list] = response.dataRows;
    }
  });
  // 公司业务
  CorporateBusiness.value = data.value.BusinessTypes.filter(item => item.name !== '公司业务')
  console.log(data.value.BusinessTypes, '公司业务=====')
};

(async () => {
  await contentList();
})();
// 点击跳转路由 文章详情页
const toPath = (item) => {
  console.log('点击了函数')
  console.log(item.articleID, 'item.articleID')
  if (!item.articleID) return;
  if (item.title.length > 20) {
    if (store.state.language) {
      ElMessage({
        message: "无内容",
        type: "warning",
      });
    } else {
      ElMessage({
        message: "No Content",
        type: "warning",
      });
    }
    return;
  }
  router.push(`/mainArticleDetails?articleID=${item.articleID}`);
};
const toCompanyBusiness = () =>{
  router.push("companyBusiness");
}
</script>
<template>
  <div class="home">
    <div class="center">
      <!--      //公司简介-->
      <div class="companyProfile" v-for="(item,index) in data.companyProfile" :key="index">
        <div class="left">
          <div class="title_ZH" v-if="store.state.language">{{ item.name }}</div>
          <div class="title_EN" v-if="!store.state.language">{{ item.subTitle }}</div>
          <div class="content_ZH" v-if="store.state.language">{{ item.description }}</div>
          <div class="content_EN" v-if="!store.state.language">{{ item.descriptionEnglish }}</div>
        </div>
        <div class="right">
          <img :src="item.listImage" alt="">
        </div>
        <div class="border_bottom"></div>

      </div>
      <!--      企业背景-->
      <div class="Background" v-for="(item,index) in data.Background" :key="index">
        <div class="title_ZH" v-if="store.state.language">{{ item.name }}</div>
        <div class="title_EN" v-if="!store.state.language">{{ item.subTitle }}</div>
        <div class="content">
          <div class="left">
            <div class="top description" v-if="store.state.language">{{ item.description }}</div>
            <div class="middle contentText" v-if="!store.state.language">{{ item.descriptionEnglish }}</div>
            <div class="bottom" @click="toPath(item)"><span>LEARN <br> MORE</span></div>
          </div>
          <div class="right">
            <img :src="item.listImage" alt="">
          </div>
        </div>
      </div>
      <!--      企业使命-->
      <div class="Corporate" v-if="navigatorArr.length > 0" v-show="navigatorArr.find(x => x.name === '企业使命').navigatorID">
        <div class="title_ZH" v-if="store.state.language && navigatorArr.length > 0">{{ navigatorArr.find(x => x.name === '企业使命').name }}</div>
        <div class="title_EN" v-if="!store.state.language && navigatorArr.length > 0">{{ navigatorArr.find(x => x.name === '企业使命').nameEN }}</div>
      </div>
      <div class="corporate">
        <!--使命-->
        <div class="mission" v-for="(item,index) in data.Corporate" :key="index"
             @click="toggleActive(index,item)">
          <div class="listImage">
            <img :src="item.listImage" alt="">
          </div>
          <div class="content_ZH" :class="{ active: activeIndex == index }" v-if="store.state.language">
            {{ item.description }}
          </div>
          <div class="content_EN" :class="{ active: activeIndex == index }" v-if="!store.state.language">
            {{ item.descriptionEnglish }}
          </div>
        </div>
      </div>
      <!--      公司价值-->
      <!-- <div class="CompanyValue">
        <div class="title_ZH" v-if="store.state.language">{{ data.CompanyValue[0]?.name }}</div>
        <div class="title_EN" v-if="!store.state.language">{{ data.CompanyValue[0]?.subTitle }}</div>
      </div>
      <div class="companyValue">
        <div class="CivilAviationOperations" v-for="(item,index) in data.CompanyValue.slice(1)" :key="index">
          <div class="listImage">
            <img :src="item.listImage" alt="">
          </div>
          <div class="description_ZH" v-if="store.state.language">{{ item.description }}</div>
          <div class="description_EN" v-if="!store.state.language">{{ item.descriptionEnglish }}</div>
          <div class="content_ZH" v-if="store.state.language" v-html="item.contentText"></div>
          <div class="content_EN" v-if="!store.state.language" v-html="item.contentEnglish"></div>
        </div>
      </div> -->
      <blockA v-if="navigatorArr.length > 0"  
        :navigatorID="navigatorArr.find(x => x.name === '公司价值').navigatorID"/>
    </div>
    <!--    四大优势-->
    <!-- <div class="Fouradvantages" :style="`background-image: url('${data.Fouradvantages[0]?.listImage}')`">
      <div class="Fouradvantages">
        <div class="title_ZH" v-if="store.state.language">{{ data.Fouradvantages[0]?.name }}</div>
        <div class="title_EN" v-if="!store.state.language">{{ data.Fouradvantages[0]?.subTitle }}</div>
        <div class="fouradvantages">
          <div class="HighTension" @click="FourActive(index,item)"
               v-for="(item,index) in data.Fouradvantages.slice(1)" :key="index">
            <div class="top">
              <div class="img">
                <img :src="item.listImage" alt="">
              </div>
              <div class="title_ZH" v-if="store.state.language">{{ item.name }}</div>
              <div class="title_EN" v-if="!store.state.language">{{ item.subTitle }}</div>
              <div class="content_ZH" v-if="store.state.language">{{ item.description }}</div>
              <div class="content_EN" v-if="!store.state.language">{{ item.descriptionEnglish }}</div>
            </div>
            <div class="bottom" :class="{ active: FourIndex == index }"></div>
          </div>
        </div>
      </div>
    </div> -->
    <blockB v-if="navigatorArr.length > 0" 
      :navigatorID="navigatorArr.find(x => x.name === '四大优势').navigatorID"/>

    <div class="center">
      <!--公司业务-->
      <div class="BusinessTypesList">
        <div class="BusinessTypes_2">
          <div class="title_ZH" v-if="store.state.language && navigatorArr.length > 0">{{ navigatorArr.find(x => x.name === '公司业务').name }}</div>
          <div class="title_EN" v-if="!store.state.language && navigatorArr.length > 0">{{ navigatorArr.find(x => x.name === '公司业务').nameEN }}</div>
          <div class="button" @click="toCompanyBusiness()">
                Click more
              </div>
        </div>
        <div class="businessTypesList">
          <div class="BusinessTypesItem" :style="{ transform: `translateX(-${itemIndex * 100}%)` }"
               v-for="(item,index) in data.BusinessTypes" :key="index">
            <div class="left">
              <img :src="item?.faceImage" alt="">
            </div>
            <div class="right">
              <div class="title_ZH" v-if="store.state.language">{{ item?.name }}</div>
              <div class="title_EN" v-if="!store.state.language">{{ item?.subTitle }}</div>
              <div class="content_ZH" v-if="store.state.language" v-html="item.contentText"></div>
              <div class="content_EN" v-if="!store.state.language" v-html="item.contentEnglish"></div>
              <div class="button" @click="toPath(item)">
                Click more
              </div>
            </div>
          </div>
        </div>
        <div class="btns">
          <span @click="prevItem"></span>
          <span @click="nextItem"></span>
        </div>
      </div>
      <!--      发展目标-->
      <div class="DevelopmentGoals">
        <div class="title_ZH" v-if="store.state.language && navigatorArr.length > 0">{{ navigatorArr.find(x => x.name === '发展目标').name }}</div>
        <div class="title_EN" v-if="!store.state.language && navigatorArr.length > 0">{{ navigatorArr.find(x => x.name === '发展目标').nameEN }}</div>
        <div class="content_ZH" v-if="store.state.language">{{ data.DevelopmentGoals[0]?.description }}</div>
        <div class="content_EN" v-if="!store.state.language">{{ data.DevelopmentGoals[0]?.descriptionEnglish }}</div>
        <div class="DevelopmentGoalsItem">
          <div class="item" v-for="(item,index) in data.DevelopmentGoals" :key="index">
            <span><img :src="item.listImage" alt=""></span>
            <div class="content_ZH" v-if="store.state.language">{{ item.description }}</div>
            <div class="content_EN" v-if="!store.state.language">{{ item.descriptionEnglish }}</div>
            <div class="bottom">
<!--              <p>→</p>-->
<!--              <span>{{ index > 10 ? index + 1 : '0' + (index + 1) }}</span>-->
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<style lang="scss" scoped>
@media screen and (max-width: 900px) {
  .home {
    > div.center {
      width: 90%;

      > div {
        margin: 0 auto 20px auto;

        &:nth-of-type(1) {
          margin: 20px auto 20px auto;

        }
      }

      .companyProfile {
        .left {
          flex: 1;
          height: 180px;

          .title_ZH, .title_EN {
            font-size: 18px;
          }

          .content_ZH, .content_EN {
            //margin-bottom: 0;
            font-size: 14px;
            line-height: 20px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5; /* 控制显示的行数 */
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
          }
        }

        .right {
          img {
            height: 120px;
          }
        }
      }

      .Background {
        .title_ZH, .title_EN {
          font-size: 18px;
          padding: 10px;
        }

        .content {
          height: 260px;
          border-radius: 15px;
          overflow: hidden;

          .left {
            padding: 10px;
            align-items: center;

            .top.description {
              font-size: 14px;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 5; /* 控制显示的行数 */
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
            }
            .middle.contentText{
              font-size: 14px;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 5; /* 控制显示的行数 */
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
            }

            .bottom {
              width: 100px;
              height: 37px;
              border-radius: 5px;
              overflow: hidden;

              span {
                font-size: 12px;
                letter-spacing: 0;
                line-height: 12px;
              }
            }
          }

          .right {
            height: 260px;
          }
        }
      }

      div.Corporate {
        margin-bottom: 10px;

        .title_ZH, .title_EN {
          font-size: 18px;
        }
      }

      div.corporate {
        flex-direction: column;
        margin-top: 20px;

        div.mission {
          width: 100%;
          display: flex;
          align-items: center;

          .listImage {
            width: 55px;
            height: 70px;
            margin-right: 10px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 55px;
              height: auto;
            }
          }

          .content_ZH, .content_EN {
            font-size: 14px;
          }
        }
      }

      div.CompanyValue {
        margin-bottom: -10px;

        .title_ZH, .title_EN {
          font-size: 18px;
        }
      }

      div.companyValue {
        flex-direction: column;
        margin-top: 20px;

        .CivilAviationOperations {
          width: 100%;
          margin: 10px 0;

          .listImage {
            img {
              height: 160px;
            }
          }

          .description_ZH, .description_EN {
            font-size: 16px;
            margin: 10px auto;
            font-weight: 600;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
          }

          .content_ZH, content_EN {
            font-size: 14px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
          }
        }
      }

      .BusinessTypesList {
        .BusinessTypes_2 {
          .title_ZH, .title_EN {
            font-size: 18px;
          }
        }

        .businessTypesList {
          .BusinessTypesItem {
            min-width: 100%;
            padding: 10px 17px 0 17px;

            .left {
              min-width: 150px;
              height: 200px;
              margin-right: 10px;

              img {
                width: 150px;
                height: 200px;
              }
            }

            .right {
              > div {
                margin-bottom: 10px;
              }

              .title_ZH, .title_EN {
                font-size: 16px;
              }

              .content_ZH, .content_EN {
                font-size: 14px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
              }

              .button {
                width: 100px;
                height: 37px;
                border-radius: 5px;
                overflow: hidden;
                padding: 0 5px;

                span {
                  font-size: 12px;
                  letter-spacing: 0;
                  line-height: 12px;
                }
              }
            }
          }
        }
        div.btns{
          span:first-of-type {
            width: 15px;
            height: 15px;
            position: absolute;
            left: 5px;
            top: 50%;
            transform: translateY(-50%);

            &::after {
              width: 15px;
              height: 15px;
              border-width: 2px;
              border-style: solid;
              border-color: transparent transparent #a4a6ac #a4a6ac;
              display: block;
              content: '';
              transform: rotate(45deg);
            }
          }

          span:nth-of-type(2) {
            width: 15px;
            height: 15px;
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);

            &::after {
              width: 15px;
              height: 15px;
              border-width: 2px;
              border-style: solid;
              border-color: #a4a6ac #a4a6ac transparent transparent;
              display: block;
              content: '';
              transform: rotate(45deg);
            }
          }
        }
      }
      .DevelopmentGoals{
        .title_ZH,.title_EN{
          font-size: 18px;
          margin-bottom: 10px;
        }

        .content_ZH,.content_EN{
          font-size: 14px;
          margin-bottom: 10px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* 控制显示的行数 */
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }
        .DevelopmentGoalsItem{
          flex-wrap: nowrap;
          flex-direction: column;
          .item{
            width: 100%;
            margin: 0 0 10px 0;
            padding: 15px;
            span{
              text-align: center;
              img{
                width: 70px;
                height: 70px;
              }
            }
            .content_ZH,.content_EN{
              font-size: 14px;
            }
            .bottom{
              span{
                font-size: 40px;
              }
            }
          }
        }
      }
    }

    > div.Fouradvantages {
      background-position: left top;
      background-size: auto 100%;
      padding: 10px;

      > div.Fouradvantages {
        width: 90%;

        .title_ZH, title_EN {
          font-size: 18px;
        }

        .fouradvantages {
          flex-wrap: wrap;

          .HighTension {
            border: 1px solid #368dff;
            background-color: rgb(255, 255, 255, 0.2);
            padding: 10px;
            border-radius: 15px;
            width: calc(50% - 10px);
            margin: 0 10px 10px 0;

            &:nth-last-child(2n) {
              margin-right: 0;
            }

            .top {
              .img {
                width: 100%;
                margin: 0 0 10px 0;
                text-align: center;

                img {
                  width: 30px;
                  height: 30px;
                }
              }

              .title_ZH,.title_EN {
                font-size: 14px;
                margin-bottom: 8px;
              }

              .content_ZH,.content_EN {
                font-size: 12px;
                color: #000;
                display: none;
              }
            }

            .bottom {
              display: none;
            }

            .bottom {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}

.home {
  //版心
  > .center {
    width: 1000px;
    margin: 0 auto;

    > div {
      margin: 90px auto;

      &:nth-of-type(1) {
        margin: 30px auto 90px auto;
      }
    }
  }

  .Breadcrumb {
    padding: 20px 0;
  }


  @media screen and (min-width: 900px) and (max-width: 1024px) {
    .center {
      width: 90%;

      > div {
        margin: 0 auto 60px auto;

        &:nth-of-type(1) {
          margin: 30px auto 60px auto;
        }
      }
    }
    > div.Fouradvantages > div.Fouradvantages {
      width: 90%;
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1200px) {
    .center {
      width: 800px;

      > div {
        margin: 0 auto 60px auto;

        &:nth-of-type(1) {
          margin: 30px auto 60px auto;
        }
      }
    }
    > div.Fouradvantages > div.Fouradvantages {
      min-width: 800px;
    }
  }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .center {
      width: 1000px;

      > div {
        margin: 0 auto 90px auto;

        &:nth-of-type(1) {
          margin: 30px auto 90px auto;
        }
      }
    }
    > div.Fouradvantages > div.Fouradvantages {
      min-width: 1000px;
    }
    .BusinessTypesList {
      .businessTypesList {
        .BusinessTypesItem {
          min-width: 1000px;;
        }
      }
    }
  }
  @media screen and (min-width: 1440px) and (max-width: 1920px) {
    .center {
      width: 1200px;

      > div {
        margin: 0 auto 100px auto;

        &:nth-of-type(1) {
          margin: 30px auto 100px auto;
        }
      }
    }
    > div.Fouradvantages > div.Fouradvantages {
      min-width: 1200px;
    }
  }
  @media screen and (min-width: 1920px) {
    .center {
      width: 1400px; /* 根据需要设置大屏幕的版心大小 */
      > div {
        margin: 0 auto 120px auto;

        &:nth-of-type(1) {
          margin: 30px auto 120px auto;
        }
      }
    }
    > div.Fouradvantages > div.Fouradvantages {
      min-width: 1400px;
    }
  }
  //公司简介
  .companyProfile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &:hover {
      .border_bottom {
        width: 100%;
      }
    }

    .left {
      width: 50rem;
      height: 18.75rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .title_ZH, .title_EN {
        font-size: 36px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #404040;
        letter-spacing: 0.125rem;
      }

      .title_EN {
        font-family: PingFang-SC-Heavy, PingFang-SC;
        font-weight: 800;
      }

      .content_ZH {
        font-size: 1.5rem;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #262729;
        line-height: 2.75rem;
        letter-spacing: 0.0625rem;
      }

      .content_EN {
        font-size: 0.875rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        line-height: 1.75rem;
      }


      > div {
        margin-bottom: 0.8125rem;
      }
    }

    .right {
      //flex: 1;
      img {
        //width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .border_bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 30%;
      height: 0.25rem;
      background-color: #e6e7e9;
      transition: 0.5s;

    }
  }

  //企业背景
  .Background {
    display: flex;
    flex-direction: column;

    .title_ZH, .title_EN {
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #404040;
      line-height: 3.125rem;
      letter-spacing: 0.125rem;
      padding: 2.5rem;
    }

    .title_EN {
      font-weight: 800;
    }

    .content {
      display: flex;
      height: 37.5rem;

      .left {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        background-color: #445e8b;
        padding: 4.375rem;
        //中文内容
        .description {
          line-height: 1.875rem;
          font-size: 1.125rem;
          color: #f5f6f8;
        }

        //英文内容
        .contentText {
          line-height: 1.875rem;
          font-size: 1rem;
          color: #afbacc;
        }

        .bottom {
          width: 12.5rem;
          height: 3.75rem;
          background: #d8e4f8;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          span {
            font-size: 0.75rem;
            font-family: AppleSystemUIFont;
            color: #262729;
            line-height: 1.125rem;
            letter-spacing: 0.1875rem;
          }
        }

      }

      .right {
        width: 50%;
        height: 37.5rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  //企业使命
  .center > div.Corporate {
    margin-bottom: 3.125rem;

    .title_ZH, .title_EN {
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #404040;
      letter-spacing: 0.125rem;
    }

    .title_EN {
      font-family: PingFang-SC-Heavy, PingFang-SC;
      font-weight: 800;
    }

  }

  //企业使命 愿景 价值
  .center > div.corporate {
    margin-top: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    > div {
      width: calc(100% / 3 - 5rem);
      margin-right: 20px;
      cursor: pointer;

      &:nth-last-child(1) {
        margin-right: 0;
      }

      .listImage {
        width: 100%;
        height: 120px;
        margin-bottom: 50px;
        text-align: center;

        img {
          //width: 50%;
          height: 100%;
        }
      }

      .content_ZH {
        font-size: 1.125rem;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #262729;
        letter-spacing: 0.0625rem;
      }

      .content_ZH.active, .content_EN.active {
        color: #2653a3;
      }

      .content_EN {
        font-size: 0.875rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        line-height: 1.75rem;
      }
    }
  }

  //公司价值
  .center > div.CompanyValue {
    margin-bottom: -2.125rem;

    .title_ZH, .title_EN {
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #404040;
      letter-spacing: 0.125rem;
    }

    .title_EN {
      font-family: PingFang-SC-Heavy, PingFang-SC;
      font-weight: 800;
    }
  }

  .center > div.companyValue {
    margin-top: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > div {
      width: calc(50% - 2.5rem);
      margin: 0 2.5rem 2.5rem 0;
      border: 1px solid #ddd;
      padding: 10px;

      &:nth-child(2n) {
        margin-right: 0;
      }

      .listImage {
        img {
          width: 100%;
          height: 350px;
          object-fit: cover;
        }
      }

      .description_ZH, .description_EN {
        font-size: 20px;
        margin: 1.875rem auto;
        font-weight: 600;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* 控制显示的行数 */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }

      .content_ZH, .content_EN {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* 控制显示的行数 */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
    }
  }

  > .Fouradvantages {
    width: 100%;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: left center;
    padding: 1.125rem 0;

    > .Fouradvantages {
      width: 1000px;
      margin: 0 auto;

      > .title_ZH, .title_EN {
        font-size: 36px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        letter-spacing: 0.125rem;
        color: #FFF;
      }

      > .title_EN {
        font-family: PingFang-SC-Heavy, PingFang-SC;
        font-weight: 800;
        color: #D7D7D7;
      }

      .fouradvantages {
        margin-top: 1.375rem;
        display: flex;
        justify-content: space-between;

        > div {
          width: calc(100% / 4 - 3.75rem);
          margin-right: 1.25rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          &:nth-last-child(1) {
            margin-right: 0;
          }

          .top {
            .img {
              width: 50%;
              margin: 0 auto 25px auto;

              img {
                width: 75px;
                height: 75px;
              }
            }

            > .title_ZH, .title_EN {
              font-size: 20px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              letter-spacing: 0.125rem;
              color: #FFF;
              margin-bottom: 1rem;
            }

            > .title_EN {
              font-family: PingFang-SC-Heavy, PingFang-SC;
              font-weight: 800;
              color: #D7D7D7;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2; /* 控制显示的行数 */
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
            }

            .content_ZH {
              font-size: 1rem;
              font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: 300;
              color: #d0d0d0;
              letter-spacing: 0.0625rem;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 4; /* 控制显示的行数 */
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
            }

            .content_EN {
              font-size: 0.875rem;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 300;
              color: #d0d0d0;
              line-height: 1.75rem;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 4; /* 控制显示的行数 */
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
            }
          }

          .bottom {
            margin-top: 1.25rem;
            width: 100%;
            height: 0.25rem;
            background-color: rgba(0, 0, 0, 0.4);
          }

          .bottom.active {
            background-color: rgb(253, 203, 40);
          }


        }
      }


    }
  }

  //公司业务

  //公司业务列表
  .BusinessTypesList {
    .BusinessTypes_2 {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .title_ZH, .title_EN {
        font-size: 36px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #404040;
        letter-spacing: 0.125rem;
      }

      .title_EN {
        font-family: PingFang-SC-Heavy, PingFang-SC;
        font-weight: 800;
      }
      .button {
            width: 12.5rem;
            height: 3.125rem;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.625rem 2.5rem;
            border: 1px solid #2d9379;
            color: #2d9379;
            cursor: pointer;
          }
    }

    position: relative;

    .businessTypesList {
      display: flex;
      overflow: hidden;

      .BusinessTypesItem {
        width: 100%;
        padding: 5rem;
        display: flex;
        justify-content: space-around;
        transition: 0.5s;
        transform: translateX(-100%);

        .left {
          min-width: 21.875rem;
          height: 25rem;
          border-radius: 1.25rem;
          overflow: hidden;
          margin-right: 5rem;

          img {
            width: 21.875rem;
            height: 25rem;
            object-fit: cover;
          }

        }

        .right {
          display: flex;
          flex-direction: column;
          justify-content: center;

          > div {
            margin-bottom: 1.875rem;
          }

          .title_ZH, .title_EN {
            font-size: 36px;
            font-weight: 700;
            color: #093892;
          }

          .content_ZH, .content_EN {
            font-size: 0.875rem;
            color: #666668;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 4; /* 控制显示的行数 */
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
          }

          .button {
            width: 12.5rem;
            height: 3.125rem;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.625rem 2.5rem;
            border: 1px solid #2d9379;
            color: #2d9379;
            cursor: pointer;
          }
        }
      }

    }

    .btns {
      cursor: pointer;

      span:first-of-type {
        width: 1.25rem;
        height: 2.5rem;
        position: absolute;
        left: 1.25rem;
        top: 50%;
        transform: translateY(-50%);

        &::after {
          width: 1.25rem;
          height: 1.25rem;
          border-width: 0.25rem;
          border-style: solid;
          border-color: transparent transparent #a4a6ac #a4a6ac;
          display: block;
          content: '';
          transform: rotate(45deg);
        }
      }

      span:nth-of-type(2) {
        width: 1.25rem;
        height: 2.5rem;
        position: absolute;
        right: 1.25rem;
        top: 50%;
        transform: translateY(-50%);

        &::after {
          width: 1.25rem;
          height: 1.25rem;
          border-width: 0.25rem;
          border-style: solid;
          border-color: #a4a6ac #a4a6ac transparent transparent;
          display: block;
          content: '';
          transform: rotate(45deg);
        }
      }
    }
  }

  //发展目标
  .DevelopmentGoals {
    > .title_ZH, .title_EN {
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      letter-spacing: 0.125rem;
      margin-bottom: 1.25rem;
    }

    > .title_EN {
      font-family: PingFang-SC-Heavy, PingFang-SC;
      font-weight: 800;
      margin-bottom: 2.5rem;
    }

    > .content_ZH {
      font-size: 1.125rem;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #666668;
      letter-spacing: 0.0625rem;
      margin-bottom: 1.25rem;
    }

    > .content_EN {
      font-size: 0.875rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 1.75rem;
      margin-bottom: 3.75rem;
    }

    .DevelopmentGoalsItem {
      display: flex;
      flex-wrap: wrap;

      .item {
        width: calc(100% / 2 - 2.5rem);
        margin: 0 2.5rem 2.5rem 0;
        padding: 2.5rem;
        background-color: #e9f3fd;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        &:nth-of-type(2), &:nth-of-type(3) {
          background-color: #fdf9e9;
        }

        &:nth-child(2n) {
          margin-right: 0;
        }
        span{
          text-align: center;
          img{
            width: 120px;
            height: 120px;
          }
        }
        > .content_ZH {
          font-size: 1.25rem;
          font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 600;
          color: #262729;
          letter-spacing: 0.0625rem;
          margin-bottom: 10px;
        }

        > .content_EN {
          font-size: 1rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #606368;
          line-height: 1.75rem;
        }

        .bottom {
          width: 80%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          > span {
            font-size: 3.75rem;
            color: rgba(0, 0, 0, 0.3);
          }

          > p {
            font-size: 1.25rem;
          }
        }

      }
    }
  }
}
</style>