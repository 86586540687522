<template>
  <header :style="activeUrl !=='homePage'?`background-image: url(${bannerImage})`:''">
    <div class="header">
      <div class="header_logo">
        <img src="../../../public/iconTitle.png" alt=""/>
      </div>
      <ul>
        <li
            v-for="(item, index) in store?.state?.nav?.headerList"
            @click="bthClick(item)"
            :key="index"
            :class="[{isActive:activeUrl==item.functionURL}]"
        >
          {{ store.state.language ? item.name : item.shortName }}
        </li>
      </ul>
      <span class="language" @click="changeLanguage" v-if="store.state.language"><img src="../../assets/img/EN.png"
                                                                                      alt=""></span>
      <span class="language" @click="changeLanguage" v-else><img src="../../assets/img/ZH.png" alt=""></span>
    </div>
    <div class="headerApp">
      <img @click="navShow=true" style="width: 20px" src="../../assets/img/moreList.png" alt="">
      <el-drawer
          custom-class="navStyle"
          v-model="navShow"
          direction="ttb"
          :before-close="handleClose"
          :show-close="false"
          :with-header="false"
          size="450px"
      >
        <ul>
          <li
              v-for="(item, index) in store?.state?.nav?.headerList"
              @click="bthClick(item)"
              :key="index"
              :class="[{isActiveApp:activeUrl===item.functionURL}]"
          >
            {{ store.state.language ? item.name : item.shortName }}
          </li>
        </ul>
      </el-drawer>
      <div class="header_logo">
        <img src="../../../public/iconTitle.png" alt=""/>
      </div>
      <span class="language" @click="changeLanguage" v-if="store.state.language"><img src="../../assets/img/EN.png"
                                                                                      alt=""></span>
      <span class="language" @click="changeLanguage" v-else><img src="../../assets/img/ZH.png" alt=""></span>
    </div>
    <div class="swiper" v-if="activeUrl ==='homePage'">
      <el-carousel :height="isSmallScreen?'30vh':'70vh'" autoplay>
        <el-carousel-item
            v-for="(item, index) in store?.state?.nav?.homeSwiper "
            :key="index"
        >
          <img :src="item.picturePath" alt=""/>
        </el-carousel-item>
      </el-carousel>
    </div>
  </header>
</template>
<script setup>
import {Expand} from '@element-plus/icons-vue'
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {inject, onBeforeMount, onMounted, reactive, ref, watch, watchEffect} from "vue";
import Common from "@/common/common.js";
import {getCartGoodsTotal} from "@/common/api.js";

const windowWidth = ref(window.innerWidth);
const isSmallScreen = ref(windowWidth.value < 900);
watchEffect(() => {
  const handleResize = () => {
    windowWidth.value = window.innerWidth;
    isSmallScreen.value = windowWidth.value < 900;
  };

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
});
const bannerImage = require('../../assets/img/banner4.png');
// 创建响应式变量
const navShow = ref(false)
// 处理关闭事件
const handleClose = (done) => {
  done();
};
let login = inject("login");
const store = useStore();
const router = useRouter();
const data = reactive({
  language: Common.language,
});
const activeUrl = ref('homePage')
const swiperList = ref([
  {picturePath: require('../../assets/img/lunbo1.png')},
  {picturePath: require('../../assets/img/lunbo2.png')},
  {picturePath: require('../../assets/img/lunbo3.png')},
  {picturePath: require('../../assets/img/banner.png')},
])
watch(
    () => router.currentRoute.value.fullPath,
    (newPath, oldPath) => {
      console.log(newPath, '路由变化了')
      if (newPath === '/') {
        activeUrl.value = 'homePage'
      } else {
        activeUrl.value = newPath.replace('/', '');
      }
    }
);
onMounted(() => {
  let path = router.currentRoute.value.fullPath.replace('/', '')
  if (path === '') {
    activeUrl.value = 'homePage'
  } else {
    activeUrl.value = path;
  }
})
const changeLanguage = (item) => {
  if (store.state.language) {
    store.commit('languageState', false);
  } else {
    store.commit('languageState', true);
  }
};

const bthClick = (item) => {
  activeUrl.value = item.functionURL
  navShow.value = false
  var url = '/'+item.functionURL+'/'+item.navigatorID
  console.log(url)
  router.push(item.functionURL);
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 900px) {
  .navStyle {
    margin-top: 30px;
  }
  header[style] {
    min-height: auto;

    .header {
      display: none;
    }

    .headerApp {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .header_logo {
        img {
          width: 20px;
        }
      }

      .language {
        img {
          width: 30px;
        }
      }

      ul {
        font-size: 14px;

        li {
          line-height: 60px;
          border-bottom: 1px solid #ddd;
        }
      }
    }

    .swiper {
      height: 30vh;
      img {
        width: 100%;
        height: 30vh;
        object-fit: cover;
      }
    }
  }
}

header {
  // width: 100%;
  //height: 100%;
  min-height: 60vh;
  // position: fixed;
  // top: 0;
  // left: 0;
  z-index: 666;
  border-bottom: 1px solid #000000;
  //background-image: url("../../assets/img/banner3.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.header {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  //position: fixed;
  //background-color: rgba(75, 130, 217);
  > h3 {
    height: 28px;
    font-size: 36px;
    font-weight: bold;
    color: #000000;
    line-height: 23px;
    padding-left: 60px;
  }

  > ul {
    display: flex;
    justify-content: space-around;
    width: 80%;

    li {
      // margin-left: 54px;
      font-size: 18px;
      font-weight: bold;
      color: #545151;
      line-height: 23px;
      cursor: pointer;
      position: relative;

      .cartNumber {
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border-radius: 100%;
        background: red;
        color: #ffffff;
        position: absolute;
        right: -5px;
        top: -10px;
      }

      .item {
        margin-top: -10px;
      }
    }

    li.isActive {
      position: relative;

      &::after {
        position: absolute;
        bottom: -10px;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 3px;
        background-color: #134498;
        content: '';
      }
    }

  }

  .header_logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 7rem;

    img {
      height: 3rem;
    }
  }

  .language {
    //position: absolute;
    //right: 20px;
    //top: 20%;
    cursor: pointer;
    transform: translateY(-50%);

    img {
      width: 40px;
      height: 40px;
    }
  }
}

.headerApp {
  display: none;
  ul{
    li.isActiveApp{
      color: #134498;
    }
  }
}

.swiper {
  width: 100%;
  position: relative;

  img {
    width: 100%;
    height: 70vh;
    //object-fit: cover;
  }
}
</style>