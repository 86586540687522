const routers = [
    {
        path: '/homePage',
        name: 'homePage',
        meta: { title_CN: '首页',title_EN: 'Home' },
        component: () => import(/* webpackChunkName: "homePage" */ '../../views/wanhuaguPcView/homePage/homePage.vue')
    }, {
        path: '/companyWorth',
        name: 'companyWorth',
        meta: { title_CN: '公司价值',title_EN: 'Company value' },
        component: () => import(/* webpackChunkName: "companyWorth" */ '../../views/wanhuaguPcView/companyWorth/companyWorth.vue')
    }, {
        path: '/companyBusiness',
        name: 'companyBusiness',
        meta: { title_CN: '公司业务',title_EN: 'Business types' },
        component: () => import(/* webpackChunkName: "companyBusiness" */ '../../views/wanhuaguPcView/companyBusiness/companyBusiness.vue')
    }, {
        path: '/industryTrends',
        name: 'industryTrends',
        meta: { title_CN: '行业动态',title_EN: 'Industry trends' },
        component: () => import(/* webpackChunkName: "industryTrends" */ '../../views/wanhuaguPcView/industryTrends/industryTrends.vue')
    }, {
        path: '/CustomerStories',
        name: 'CustomerStories',
        meta: { title_CN: '客户案例',title_EN: 'Excellent service' },
        component: () => import(/* webpackChunkName: "CustomerStories" */ '../../views/wanhuaguPcView/CustomerStories/CustomerStories.vue')
    }, {
        path: '/aboutUs',
        name: 'aboutUs',
        meta: { title_CN: '关于我们',title_EN: 'About us' },
        component: () => import(/* webpackChunkName: "aboutUs" */ '../../views/wanhuaguPcView/aboutUs/aboutUs.vue')
    }, {
        path: '/JoinUs',
        name: 'JoinUs',
        meta: { title_CN: '加入我们',title_EN: 'Join us' },
        component: () => import(/* webpackChunkName: "JoinUs" */ '../../views/wanhuaguPcView/JoinUs/JoinUs.vue')
    },
    // 文章详情(用于底部点击跳转)
    {
        path: '/articleDetails',
        name: 'articleDetails',
        component: () => import(/* webpackChunkName: "about" */ '../../views/wanhuaguPcView/articleDetails/articleDetails.vue')
    },
    // 文章详情(用于主体点击跳转)
    {
        path: '/mainArticleDetails',
        name: 'mainArticleDetails',
        component: () => import(/* webpackChunkName: "about" */ '../../views/wanhuaguPcView/articleDetails/mainArticleDetails.vue')
    }
]
export default routers