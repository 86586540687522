import "./assets/icon/SourceHanSansCN-VF-2.css"
import { createApp } from 'vue'
import store from './store'
import router from './router'
import './assets/style/style.css'
import App from './App.vue'
import Common from './common/common'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/style/public.css'
import animated from 'animate.css'
import login from './components/login/index'

let Vue = createApp(App)

Vue.provide('Common', Common)
Vue.provide('login', login)

Vue.use(store).use(router).use(animated).use(ElementPlus).mount('#app')

// 导入所有组件
// const requireComponent = require.context(
//     './components/articleBlock', // 组件所在的文件夹路径
//     false, // 是否在子文件夹中查找
//     /\.vue/ // 组件文件的后缀名
//   )
  
//   // 遍历所有组件并注册
//   requireComponent.keys().forEach(fileName => {
//     // 获取组件配置
//     const componentConfig = requireComponent(fileName)
  
//     // 获取组件的 PascalCase 命名
//     const componentName = upperFirst(
//       camelCase(
//         // 移除文件名开头的 './' 和扩展名
//         fileName.replace(/^\.\/(.*)\.\w+/, '$1')
//       )
//     )
  
//     // 全局注册组件
//     Vue.component(
//       componentName,
//       componentConfig.default || componentConfig
//     )
//   })