<script setup>
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {ElMessage} from "element-plus";
import store from "@/store/index";
import {getNavigatorSimpleArticleList} from "@/common/api";
import {ref} from "vue";

const {state} = useStore();
const router = useRouter();
console.log("888555222", state.nav.footerList);
const navigatorID = 'aaa506a3a02f4f819380b57eec368855';
const footerInfo = ref([])
const toPath = (ite) => {
  console.log(ite.articleID, 'ite.articleID')
  if (!ite.articleID) return;
  if (ite.title.length > 20) {
    if (store.state.language) {
      ElMessage({
        message: "无内容",
        type: "warning",
      });
    } else {
      ElMessage({
        message: "No Content",
        type: "warning",
      });
    }
    return;
  }
  router.push(`/articleDetails?articleID=${ite.articleID}`);
};
const init = () => {
  getNavigatorSimpleArticleList({
    navigatorID: navigatorID,
    sortTypeOrder: 1
  }, res => {
    console.log(res, 'resres底部文字')
    footerInfo.value = res.data.body.data.rows
  })
}
init()
const callPhone = (phone) => {
  window.open(`tel:${phone}`);
}
</script>

<template>
  <footer>
    <div class="footer">
      <div class="center">
        <div class="top">
          <div class="top_left">
            <img src="../../assets/img/footerLogo.png" alt="">
          </div>
          <div class="top_right">
            <div class="right_top">
              <div>
                <img src="../../assets/img/company.png" alt="">
                <span class="company">
                  <span>苏州聚枢信息科技有限公司</span>
                  <span>上海万欢谷信息科技有限公司</span>
                </span>
              </div>
              <div>
                <img src="../../assets/img/email.png" alt="">
                <span class="company">
                  <span>sedzhao@haoju.me</span>
                </span>
              </div>
<!--              <div>-->
<!--                <img src="../../assets/img/network.png" alt="">-->
<!--                <span class="company">-->
<!--                  <span>wangk@wanliltd.com</span>-->
<!--                </span>-->
<!--              </div>-->
            </div>
            <div class="right_bottom">
              <div>
                <img src="../../assets/img/position.png" alt="">
                <span class="company">
                  <span>上海市宝山区智慧湾15号楼</span>
                  <span>Building 15,Zhihuiwan,Baoshan district，Shanghai.</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="left" v-if="store.state.language">
            Copyright©2023 保留所有权利。
            <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">沪ICP备15029310号 - 1</a>
          </div>
          <div class="left" v-if="!store.state.language">
            Copyright©2023 保留所有权利。
            <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">&nbsp;&nbsp;Hu ICP Bei No.
              15029310 -1</a>
          </div>
          <div class="right" v-if="store.state.language">
            <span v-for="(item,index) in footerInfo" :key="index" @click="toPath(item)">
             &nbsp;&nbsp;{{ item.name }}&nbsp;&nbsp;<span v-if="index!==footerInfo.length-1">|</span>
            </span>
          </div>
          <div class="right" v-else>
            <span v-for="(item,index) in footerInfo" :key="index" @click="toPath(item)">
             &nbsp;&nbsp;{{ item.subTitle }}&nbsp;&nbsp;<span v-if="index!==footerInfo.length-1">|</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<style lang="scss" scoped>
footer {
  background-color: #f5f6fb;

  .footer {
    padding: 20px;

  }

  .center {
    width: 1000px;
    margin: 0 auto;
  }

  @media screen and (max-width: 900px) {
    .footer {
      div.top{
        font-size: 14px;
        flex-direction: column;
        .top_left{
          width: 80%;
        }
        .top_right {
          .right_top {
            display: flex;
            margin-bottom: 15px;
            margin-top: 15px;
            flex-direction: column;

            > div {
              display: flex;
              align-items: center;
              margin-right: 0;
              margin-bottom: 15px;
              &:nth-last-child(1){
                margin-right: 0;
              }

              img {
                width: 25px;
              }

              > span {
                display: flex;
                flex-direction: column;
                border-left: 1px solid #0046b9;
                padding-left: 5px;
                margin-left: 5px;
              }
            }
          }
          .right_bottom{
            display: flex;
            flex-direction: column;
            > div {
              display: flex;
              align-items: center;
              margin-right: 15px;
              &:nth-last-child(1){
                margin-right: 0;
              }

              img {
                width: 25px;
              }

              > span {
                display: flex;
                flex-direction: column;
                border-left: 1px solid #0046b9;
                padding-left: 5px;
                margin-left: 5px;
              }
            }
          }
        }
      }

      .bottom {
        font-size: 12px;
        align-items: center;
        flex-direction: column;
      }

    }
    .center {
      width: 90%;
    }
  }
  @media screen and (min-width: 900px) and (max-width: 1024px) {
    .center {
      width: 90%;
    }
    div.top{
      font-size: 14px;
      .top_right {
        .right_top {
          display: flex;
          margin-bottom: 15px;

          > div {
            display: flex;
            align-items: center;
            margin-right: 15px;
            &:nth-last-child(1){
              margin-right: 0;
            }

            img {
              width: 25px;
            }

            > span {
              display: flex;
              flex-direction: column;
              border-left: 1px solid #0046b9;
              padding-left: 5px;
              margin-left: 5px;
            }
          }
        }
        .right_bottom{
          display: flex;

          > div {
            display: flex;
            align-items: center;
            margin-right: 15px;
            &:nth-last-child(1){
              margin-right: 0;
            }

            img {
              width: 25px;
            }

            > span {
              display: flex;
              flex-direction: column;
              border-left: 1px solid #0046b9;
              padding-left: 5px;
              margin-left: 5px;
            }
          }
        }
      }
    }

  }
  @media screen and (min-width: 1024px) and (max-width: 1200px) {
    .center {
      min-width: 800px;
    }
    div.top{
      font-size: 14px;
      .top_right {
        .right_top {
          display: flex;
          margin-bottom: 15px;

          > div {
            display: flex;
            align-items: center;
            margin-right: 15px;
            &:nth-last-child(1){
              margin-right: 0;
            }

            img {
              width: 25px;
            }

            > span {
              display: flex;
              flex-direction: column;
              border-left: 1px solid #0046b9;
              padding-left: 5px;
              margin-left: 5px;
            }
          }
        }
        .right_bottom{
          display: flex;

          > div {
            display: flex;
            align-items: center;
            margin-right: 15px;
            &:nth-last-child(1){
              margin-right: 0;
            }

            img {
              width: 25px;
            }

            > span {
              display: flex;
              flex-direction: column;
              border-left: 1px solid #0046b9;
              padding-left: 5px;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .center {
      min-width: 1000px;
    }
    div.top{
      .top_right {
        .right_top {
          display: flex;
          margin-bottom: 15px;

          > div {
            display: flex;
            align-items: center;
            margin-right: 60px;
            &:nth-last-child(1){
              margin-right: 0;
            }

            img {
              width: 25px;
            }

            > span {
              display: flex;
              flex-direction: column;
              border-left: 1px solid #0046b9;
              padding-left: 5px;
              margin-left: 5px;
            }
          }
        }
        .right_bottom{
          display: flex;

          > div {
            display: flex;
            align-items: center;
            margin-right: 15px;
            &:nth-last-child(1){
              margin-right: 0;
            }

            img {
              width: 25px;
            }

            > span {
              display: flex;
              flex-direction: column;
              border-left: 1px solid #0046b9;
              padding-left: 5px;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 1440px) and (max-width: 1920px) {
    .center {
      min-width: 1200px;
    }
    div.top{
      .top_right {
        .right_top {
          display: flex;
          margin-bottom: 15px;

          > div {
            display: flex;
            align-items: center;
            margin-right: 60px;
            &:nth-last-child(1){
              margin-right: 0;
            }

            img {
              width: 25px;
            }

            > span {
              display: flex;
              flex-direction: column;
              border-left: 1px solid #0046b9;
              padding-left: 5px;
              margin-left: 5px;
            }
          }
        }
        .right_bottom{
          display: flex;

          > div {
            display: flex;
            align-items: center;
            margin-right: 15px;
            &:nth-last-child(1){
              margin-right: 0;
            }

            img {
              width: 25px;
            }

            > span {
              display: flex;
              flex-direction: column;
              border-left: 1px solid #0046b9;
              padding-left: 5px;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 1920px) {
    .center {
      width: 1400px; /* 根据需要设置大屏幕的版心大小 */
    }
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 30px;
    font-size: 15px;

    .top_left {
      width: 30%;

      img {
        width: 100%;
      }
    }

    .top_right {
      .right_top {
        white-space: nowrap;

        display: flex;
        margin-bottom: 15px;

        > div {
          display: flex;
          align-items: center;
          margin-right: 60px;
          &:nth-last-child(1){
            margin-right: 0;
          }

          img {
            width: 25px;
          }

          > span {
            display: flex;
            flex-direction: column;
            border-left: 1px solid #0046b9;
            padding-left: 5px;
            margin-left: 5px;
          }
        }
      }
      .right_bottom{
        display: flex;

        > div {
          display: flex;
          align-items: center;
          margin-right: 15px;
          &:nth-last-child(1){
            margin-right: 0;
          }

          img {
            width: 25px;
          }

          > span {
            display: flex;
            flex-direction: column;
            border-left: 1px solid #0046b9;
            padding-left: 5px;
            margin-left: 5px;
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;

    .left {
      color: #717171;

      a {
        color: #000;

        &:hover {
          border-bottom: 1px solid #636363;
        }
      }
    }

    .right {
      cursor: pointer;
      color: #717171;

      span {
        padding: 2px 0;

        &:hover {
          border-bottom: 1px solid #636363;
        }
      }

    }
  }

}
</style>
<!--<style lang="scss" scoped>-->
<!--@media screen and (max-width: 1024px){-->
<!--  footer .footer.center {-->
<!--    width: 90%;-->
<!--  }-->
<!--}-->
<!--@media screen and (min-width: 1024px) and (max-width: 1200px) {-->
<!--  footer .footer.center {-->
<!--    width: 800px;-->
<!--  }-->
<!--}-->
<!--@media screen and (min-width: 1200px) and (max-width: 1440px) {-->
<!--  footer .footer.center {-->
<!--    width: 1000px;-->
<!--  }-->
<!--}-->
<!--@media screen and (min-width: 1440px) and (max-width: 1920px) {-->
<!--  footer .footer.center {-->
<!--    width: 1200px;-->
<!--  }-->
<!--}-->
<!--@media screen and (min-width: 1920px) {-->
<!--  footer .footer.center {-->
<!--    width: 1400px; /* 根据需要设置大屏幕的版心大小 */-->
<!--  }-->
<!--}-->
<!--footer {-->
<!--  width: 100%;-->
<!--  background-color: rgba(245, 246, 251, 1);-->
<!--}-->
<!--.footer {-->
<!--  display: flex;-->
<!--  flex-direction: column;-->
<!--}-->
<!--.center {-->
<!--  width: 1000px;-->
<!--  margin: 0 auto;-->
<!--}-->
<!--.block_22 {-->
<!--  /* width: 1920px; */-->
<!--  height: 428px;-->
<!--  /* margin-top: 193px; */-->
<!--}-->

<!--.box_12 {-->
<!--  /* width: 1018px; */-->
<!--  display: flex;-->
<!--  padding-top: 70px;-->
<!--  /* margin: 83px 0 0 358px; */-->
<!--}-->

<!--.text_70 {-->
<!--  width: 397px;-->
<!--  height: 67px;-->
<!--  overflow-wrap: break-word;-->
<!--  color: rgba(33, 48, 83, 0.41);-->
<!--  font-size: 48px;-->
<!--  letter-spacing: 1px;-->
<!--  font-family: PingFangSC-Medium;-->
<!--  font-weight: 500;-->
<!--  text-align: left;-->
<!--  white-space: nowrap;-->
<!--  line-height: 67px;-->
<!--  img{-->
<!--    width: 397px;-->
<!--    transform: translateY(50%);-->
<!--  }-->
<!--}-->

<!--.text-wrapper_8 {-->
<!--  display: flex;-->
<!--  flex-direction: column;-->
<!--  width: 347px;-->
<!--  height: 95px;-->
<!--  margin: 2px 0 0 48px;-->
<!--}-->

<!--.text_71 {-->
<!--  width: 240px;-->
<!--  height: 21px;-->
<!--  overflow-wrap: break-word;-->
<!--  color: rgba(38, 39, 41, 1);-->
<!--  font-size: 15px;-->
<!--  font-family: PingFangSC-Medium;-->
<!--  font-weight: 500;-->
<!--  text-align: left;-->
<!--  white-space: nowrap;-->
<!--  line-height: 21px;-->
<!--}-->

<!--.text_72 {-->
<!--  width: 347px;-->
<!--  height: 21px;-->
<!--  overflow-wrap: break-word;-->
<!--  color: rgba(38, 39, 41, 1);-->
<!--  font-size: 15px;-->
<!--  font-family: PingFangSC-Medium;-->
<!--  font-weight: 500;-->
<!--  text-align: left;-->
<!--  white-space: nowrap;-->
<!--  line-height: 21px;-->
<!--  margin-top: 40px;-->
<!--}-->

<!--.text-wrapper_9 {-->
<!--  display: flex;-->
<!--  flex-direction: column;-->
<!--  width: 175px;-->
<!--  height: 93px;-->
<!--  margin-left: 51px;-->
<!--}-->

<!--.text_73 {-->
<!--  width: 172px;-->
<!--  height: 21px;-->
<!--  overflow-wrap: break-word;-->
<!--  color: rgba(38, 39, 41, 1);-->
<!--  font-size: 15px;-->
<!--  font-family: PingFangSC-Medium;-->
<!--  font-weight: 500;-->
<!--  text-align: left;-->
<!--  white-space: nowrap;-->
<!--  line-height: 21px;-->
<!--}-->

<!--.text_74 {-->
<!--  width: 175px;-->
<!--  height: 21px;-->
<!--  overflow-wrap: break-word;-->
<!--  color: rgba(38, 39, 41, 1);-->
<!--  font-size: 15px;-->
<!--  font-family: PingFangSC-Medium;-->
<!--  font-weight: 500;-->
<!--  text-align: left;-->
<!--  white-space: nowrap;-->
<!--  line-height: 21px;-->
<!--  margin-top: 40px;-->
<!--}-->

<!--.text-wrapper_10 {-->
<!--  /* width: 1116px; */-->
<!--  height: 59px;-->
<!--  display: flex;-->
<!--  align-items: center;-->
<!--}-->

<!--.text_75 {-->
<!--  //width: 338px;-->
<!--  width: 480px;-->
<!--  height: 25px;-->
<!--  overflow-wrap: break-word;-->
<!--  color: rgba(38, 39, 41, 0.7);-->
<!--  font-size: 18px;-->
<!--  font-family: PingFangSC-Medium;-->
<!--  font-weight: 500;-->
<!--  text-align: left;-->
<!--  white-space: nowrap;-->
<!--  line-height: 25px;-->
<!--}-->

<!--.text_76 {-->
<!--  width: 670px;-->
<!--  /* height: 42px; */-->
<!--  overflow-wrap: break-word;-->
<!--  color: rgba(38, 39, 41, 1);-->
<!--  font-size: 15px;-->
<!--  font-family: PingFangSC-Medium;-->
<!--  font-weight: 500;-->
<!--  text-align: left;-->
<!--  line-height: 21px;-->
<!--  /* margin-top: 17px; */-->
<!--  //margin-left: 110px;-->
<!--  margin: 2px 0 0 48px;-->

<!--}-->

<!--.image-wrapper_3 {-->
<!--  width: 238px;-->
<!--  height: 40px;-->
<!--  margin: 7px 0 0 358px;-->
<!--}-->

<!--.label_3 {-->
<!--  width: 40px;-->
<!--  height: 40px;-->
<!--}-->

<!--.label_4 {-->
<!--  width: 38px;-->
<!--  height: 34px;-->
<!--  margin: 6px 0 0 60px;-->
<!--}-->

<!--.label_5 {-->
<!--  width: 38px;-->
<!--  height: 38px;-->
<!--  margin: 2px 0 0 62px;-->
<!--}-->

<!--.text_77 {-->
<!--  /* width: 476px; */-->
<!--  height: 48px;-->
<!--  overflow-wrap: break-word;-->
<!--  color: rgba(93, 93, 93, 1);-->
<!--  font-size: 34px;-->
<!--  font-family: PingFangSC-Medium;-->
<!--  font-weight: 500;-->
<!--  text-align: left;-->
<!--  white-space: nowrap;-->
<!--  line-height: 48px;-->
<!--  /* margin: 16px 0 56px 636px; */-->
<!--  text-align: center;-->
<!--  padding-top: 15px;-->
<!--}-->

<!--.block_23 {-->
<!--  width: 1137px;-->
<!--  height: 53px;-->
<!--  margin: 24px 0 11px 357px;-->
<!--}-->

<!--.text-group_13 {-->
<!--  width: 464px;-->
<!--  height: 53px;-->
<!--}-->

<!--.text_78 {-->
<!--  width: 278px;-->
<!--  height: 20px;-->
<!--  overflow-wrap: break-word;-->
<!--  color: rgba(113, 113, 113, 1);-->
<!--  font-size: 14px;-->
<!--  text-align: left;-->
<!--  white-space: nowrap;-->
<!--  line-height: 20px;-->
<!--  margin-left: 1px;-->
<!--}-->

<!--.text_79 {-->
<!--  width: 464px;-->
<!--  height: 20px;-->
<!--  overflow-wrap: break-word;-->
<!--  color: rgba(113, 113, 113, 1);-->
<!--  font-size: 14px;-->
<!--  text-align: left;-->
<!--  white-space: nowrap;-->
<!--  line-height: 20px;-->
<!--  margin-top: 13px;-->
<!--  &:hover{-->
<!--    text-decoration: underline;-->
<!--    cursor: pointer;-->
<!--  }-->
<!--}-->

<!--.text_80 {-->
<!--  width: 383px;-->
<!--  height: 20px;-->
<!--  overflow-wrap: break-word;-->
<!--  color: rgba(113, 113, 113, 1);-->
<!--  font-size: 14px;-->
<!--  text-align: left;-->
<!--  white-space: nowrap;-->
<!--  line-height: 20px;-->
<!--  >span{-->
<!--    &:hover{-->
<!--      text-decoration: underline;-->
<!--      cursor: pointer;-->
<!--    }-->
<!--  }-->

<!--}-->
<!--.footer_bottom {-->
<!--  display: flex;-->
<!--  justify-content: space-between;-->
<!--  padding-top: 1rem;-->
<!--}-->
<!--</style>-->
