function phoneInput(e) {
    console.log(e)
}
export default {
    errorToBack: errorToBack,
    getStorage: getStorage,
    removeStorage: removeStorage,
    phoneInput: phoneInput,
    isWeiXinBrowser:isWeiXinBrowser,
    jumpToLoginWeiXin:jumpToLoginWeiXin,
    titleID: [{ name: "先生", titleID: '1d88af067fff4d6e95575e77f7365029' }, { name: "女士", titleID: '384aba23a5ac4344b5848250d793cd64' }],
    //应用id
    applicationID:'8a2f462a5d116c5d015d2088f9670dc4',
    //站点ID
    siteID: '8a2f462a8b3ba8d7018b3cbe523202a1',
    //公司ID
    companyID: '8a2f462a8b3ba8d7018b3cb581850251',
    shopID:'9fed6f0c3f7a49089d99697966b711d3',
    //导航ID
    navIdList: {
        footerId: '7ea29a46ec51458c9f2c3e860e046928',
        headerId: '2bac7136d86c4811a0791ba324906c0a',
        RecommendedNavigationId:'e1a910340cb44d73a4fdb98186bc7fab'//首页推荐商品导航id
    },
    withdrawDefineID:'',
    // 支付ID
    paywayID:'',
    //轮播图ID
    swiper:{
        homeId:'9b400117b59c462ca11b72bbb73a9420'
    },
    //语言
    language: true,
    // 设备ID
    deviceID: 'b916606eacd24d18acda4cc9da4e736f',//火星
    
    // defaultAvatarURL:'',
    periodType:[{id:'1',name:'日',shortName:'day'},{id:'2',name:'月',shortName:'month'},{id:'3',name:'年',shortName:'year'},{id:'10',name:'次',shortName:'times'}]
}
// 获取localStorage
function getStorage(name) {
    if (!name) return
    return window.localStorage.getItem(name)
}

// 删除localStorage
function removeStorage(name) {
    if (!name) return
    window.localStorage.removeItem(name)
}
// 当出错的时候，显示错误信息，并且跳转
function errorToBack(msg = '出错了，请重试', time = 1500) {
    console.log(msg)
    // _this.$dialog.toast({
    //     mes: msg,
    //     timeout: time
    // })
}
function isWeiXinBrowser() {
    // window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
    var ua = window.navigator.userAgent.toLowerCase()
    // 通过正则表达式匹配ua中是否含有MicroMessenger字符串
    var uas = ua.match(/MicroMessenger/i)
    if (uas) {
        if (ua.match(/MicroMessenger/i)[0] === 'micromessenger') {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}
function jumpToLoginWeiXin() {
    let that = _this;
    removeStorage('sessionID');
    removeStorage('memberID');
    that.code = ''
    var local = location.origin + '/authNew';
    var appid = 'wxa41d50db32b07abc';
    that.code = getUrlCode().code // 截取code
    if (that.code == null || that.code === '') { // 如果没有code，则去请求
        setStorage('beforeUrl', route.fullPath)
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
    } else {
        // 有code
    }
}