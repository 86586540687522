<!-- 
  组件来自页面区块《四大优势》改造而成/外观为长条形/从左到右显示小图和文章标题简介
组件需要传入数据。直接传文章数组/传导航id然后让组件查。二选一。都传的会用直接传的数据
使用示例：
<blockB :values="data.CompanyValue" :navigatorID="'2f997fc0c5184d50954a9396638abc7f'"/> 
-->

<template>
  <div class="Fouradvantages" :style="`background-image: url('${data[0]?.listImage}')`">
    <div class="Fouradvantages">
      <div class="title_ZH" v-if="store.state.language">{{ naviName }}</div>
      <div class="title_EN" v-if="!store.state.language">{{ naviNameEN }}</div>
      <div class="fouradvantages">
        <div class="HighTension" @click="FourActive(item, index)" v-for="(item, index) in data"
          :key="index">
          <div class="top">
            <div class="img">
              <img :src="item.listImage" alt="">
            </div>
            <div class="title_ZH" v-if="store.state.language">{{ item.name }}</div>
            <div class="title_EN" v-if="!store.state.language">{{ item.subTitle }}</div>
            <div class="content_ZH" v-if="store.state.language">{{ item.description }}</div>
            <div class="content_EN" v-if="!store.state.language">{{ item.descriptionEnglish }}</div>
          </div>
          <div class="bottom" :class="{ active: FourIndex == index }"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import { getNavigatorList, getNavigatorSimpleArticleList } from "@/common/api";
import router from "@/router";
import Common from "@/common/common";
import {ref, watchEffect} from "vue";


export default {
  components: { Common },
  name: 'blockB',
  computed: {
    store() {
      return store
    }
  },
  data() {
    return {
      data: [],
      naviName: "",
      naviNameEN: "",
      FourIndex : ref('0'),
    };
  },
  props: {
    values: [],
    navigatorID: String,
  },
  created() {
    //while we do not know which navi(header or main) need detail,we get both,the wrong one will not apply beacuse nocan find data
    //this.getNaviDetail(Common.navIdList.RecommendedNavigationId)
    //this.getNaviDetail(Common.navIdList.headerId)

    //从store缓存中查询 naviID和传入的相同的项，找到name和shortname（上面的方法是从接口查，没这个必要
    //因为有首页navi和主页navi两种，都跑一遍，只有其中一个会找到值
    let headerlist = Object.values(store?.state?.nav?.headerList);
    headerlist.forEach(li => {
      if (li.navigatorID === this.navigatorID) { this.naviName = li.name; this.naviNameEN = li.shortName }
    });
    let navlist = Object.values(store?.state?.nav?.navList);
    navlist.forEach(li => {
      if (li.navigatorID === this.navigatorID) { this.naviName = li.name; this.naviNameEN = li.shortName }
    });

    //get article list 
    if (this.values !== undefined && this.values.length !== 0) {
      console.log(this.values);
      this.data = this.values;
    }
    //if nodata we get 
    else {
      getNavigatorSimpleArticleList({
        navigatorID: this.navigatorID,
        sortTypeOrder: 1
      }, res => {
        this.data = res.data.body.data.rows;
      });
    }
  },
  methods: {
    //文章跳转方法
    toArticle(item, index) {
      this.FourIndex.values = index
      console.log(item)
      router.push(`/mainArticleDetails?articleID=${item.articleID}`);
    },
    //根据navigatorID获取区域name
    getNaviDetail(id) {
      //first we get all navigators
      getNavigatorList({
        siteID: Common.siteID,
        navigatorID: id,
        sortTypeOrder: 1,
        pageNumber: 999,
      }, res => {
        res.data.body.data.rows.forEach(item => {
          //then if one navigator's id equals the id send to this component,we use this navigator item
          if (item.navigatorID === this.navigatorID) { this.naviName = item.name; this.naviShortName = item.shortName }
        });
      });
    }
  },
};

</script>
<script setup>
import router from "@/router";
  // 点击四大优势 添加class（解释下，这个class是让下面那个灰条点击后变黄条
  const FourIndex = ref('0')
  const FourActive = (item, index) => {
    FourIndex.value = index
    toPath(item)
  }
  // 点击跳转路由 文章详情页
const toPath = (item) => {
  console.log('点击了函数')
  console.log(item.articleID, 'item.articleID')
  if (!item.articleID) return;
  if (item.title.length > 20) {
    if (store.state.language) {
      ElMessage({
        message: "无内容",
        type: "warning",
      });
    } else {
      ElMessage({
        message: "No Content",
        type: "warning",
      });
    }
    return;
  }
  router.push(`/mainArticleDetails?articleID=${item.articleID}`);
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 900px) {
  .home {
    > div.Fouradvantages {
      background-position: left top;
      background-size: auto 100%;
      padding: 10px;

      > div.Fouradvantages {
        width: 90%;

        .title_ZH, title_EN {
          font-size: 18px;
        }

        .fouradvantages {
          flex-wrap: wrap;

          .HighTension {
            border: 1px solid #368dff;
            background-color: rgb(255, 255, 255, 0.2);
            padding: 10px;
            border-radius: 15px;
            width: calc(50% - 10px);
            margin: 0 10px 10px 0;

            &:nth-last-child(2n) {
              margin-right: 0;
            }

            .top {
              .img {
                width: 100%;
                margin: 0 0 10px 0;
                text-align: center;

                img {
                  width: 30px;
                  height: 30px;
                }
              }

              .title_ZH,.title_EN {
                font-size: 14px;
                margin-bottom: 8px;
              }

              .content_ZH,.content_EN {
                font-size: 12px;
                color: #000;
                display: none;
              }
            }

            .bottom {
              display: none;
            }

            .bottom {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
.home{
  @media screen and (min-width: 900px) and (max-width: 1024px) {
    > div.Fouradvantages > div.Fouradvantages {
      width: 90%;
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1200px) {
    > div.Fouradvantages > div.Fouradvantages {
      min-width: 800px;
    }
  }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    > div.Fouradvantages > div.Fouradvantages {
      min-width: 1000px;
    }
  }
  @media screen and (min-width: 1440px) and (max-width: 1920px) {
    > div.Fouradvantages > div.Fouradvantages {
      min-width: 1200px;
    }
  }
  @media screen and (min-width: 1920px) {
    > div.Fouradvantages > div.Fouradvantages {
      min-width: 1400px;
    }
  }
  > .Fouradvantages {
    width: 100%;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: left center;
    padding: 1.125rem 0;

    > .Fouradvantages {
      width: 1000px;
      margin: 0 auto;

      > .title_ZH, .title_EN {
        font-size: 36px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        letter-spacing: 0.125rem;
        color: #FFF;
      }

      > .title_EN {
        font-family: PingFang-SC-Heavy, PingFang-SC;
        font-weight: 800;
        color: #D7D7D7;
      }

      .fouradvantages {
        margin-top: 1.375rem;
        display: flex;
        justify-content: space-between;

        > div {
          width: calc(100% / 4 - 3.75rem);
          margin-right: 1.25rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          &:nth-last-child(1) {
            margin-right: 0;
          }

          .top {
            .img {
              width: 50%;
              margin: 0 auto 25px auto;

              img {
                width: 75px;
                height: 75px;
              }
            }

            > .title_ZH, .title_EN {
              font-size: 20px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              letter-spacing: 0.125rem;
              color: #FFF;
              margin-bottom: 1rem;
            }

            > .title_EN {
              font-family: PingFang-SC-Heavy, PingFang-SC;
              font-weight: 800;
              color: #D7D7D7;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2; /* 控制显示的行数 */
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
            }

            .content_ZH {
              font-size: 1rem;
              font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: 300;
              color: #d0d0d0;
              letter-spacing: 0.0625rem;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 4; /* 控制显示的行数 */
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
            }

            .content_EN {
              font-size: 0.875rem;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 300;
              color: #d0d0d0;
              line-height: 1.75rem;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 4; /* 控制显示的行数 */
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
            }
          }

          .bottom {
            margin-top: 1.25rem;
            width: 100%;
            height: 0.25rem;
            background-color: rgba(0, 0, 0, 0.4);
          }

          .bottom.active {
            background-color: rgb(253, 203, 40);
          }


        }
      }


    }
  }

}
</style>